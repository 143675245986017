import { Business, Help } from '@mui/icons-material';
import Home from '@mui/icons-material/Home';
import { Route } from 'react-router';
import { AccountInfoPage } from '../pages/AccountInfo';
import { CreateOrganizationPage } from '../pages/CreateOrganization';
import { EditOrganizationPage } from '../pages/EditOrganization';
import { AdvancedTopics } from '../pages/Help/AdvancedTopics';
import { HelpPage } from '../pages/Help/HelpPage';
import { UdpKeysPage } from '../pages/Help/UdpKeys';
import { HomePage } from '../pages/HomePage';
import { OrganizationDetailsPage } from '../pages/OrganizationDetails';
import { OrganizationListPage } from '../pages/OrganizationList';
import i18n from '../translations/i18n';

export const DrawerPages = [
    {
        title: i18n.t('COMMON.HOME'),
        route: '/',
        component: HomePage,
        icon: Home,
        iconTestId: 'home-page-icon',
    },
    {
        title: i18n.t('DRAWER.HELP'),
        route: '/help',
        component: HelpPage,
        icon: Help,
        iconTestId: 'help-page-icon',
    },
];

export const DrawerDocumentationPages = (
    <>
        <Route path="/advancedTopics" element={<AdvancedTopics />} />
        <Route path="/advancedTopics/udpKeys" element={<UdpKeysPage />} />
    </>
);

export const AuthorizedDrawerPages = [
    {
        title: i18n.t('DRAWER.MY_ORGANIZATIONS'),
        route: '/organizations',
        component: OrganizationListPage,
        icon: Business,
        iconTestId: 'organization-list-page-icon',
    },
];

export const AuthorizedPages = (
    <>
        <Route path="/account" element={<AccountInfoPage />} />
        <Route path="/organizations/:id" element={<OrganizationDetailsPage />} />
        <Route path="/organizations/create" element={<CreateOrganizationPage />} />
        <Route path="/organizations/:id/edit" element={<EditOrganizationPage />} />
    </>
);
