import * as Colors from '@brightlayer-ui/colors';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { Box, Collapse } from '@mui/material';
import { MouseEventHandler, ReactElement, useRef } from 'react';

const styles = {
    listItemBox: { marginBottom: '15px', marginTop: '0px' },
};

export type DropdownListItemProps = {
    title: string;
    subtitle?: string;
    onClick?: MouseEventHandler<HTMLElement>;
    rightComponent?: ReactElement;
    leftComponent?: ReactElement;
    expandableComponent?: ReactElement;
    expanded?: boolean;
    icon?: ReactElement;
    statusColor?: string;
};

export const SelectableDropdownListItem: React.FC<DropdownListItemProps> = (props) => {
    const listItemRef = useRef<Element>(null);

    return (
        <Box data-testid={`${props.title}-list-item`} sx={styles.listItemBox} boxShadow={3}>
            <InfoListItem
                ref={listItemRef}
                title={props.title}
                backgroundColor={Colors.white[50]}
                statusColor={props.statusColor ?? Colors.blue[500]}
                hidePadding={true}
                subtitle={props.subtitle}
                divider={'full'}
                onClick={props.onClick}
                rightComponent={props.rightComponent}
                leftComponent={props.leftComponent}
                icon={props.icon}
            />

            {props.expandableComponent && (
                <Collapse
                    data-testid="expandable-content"
                    in={props.expanded}
                    timeout="auto"
                    unmountOnExit
                    onEntered={(): void => {
                        const elem = listItemRef.current;
                        if (elem) {
                            // This behavior is needed in the Tutorial. If we ever want to use the SelectableDropdownListItem
                            // with expandable content anywhere else, and this auto scrolling isn't appropriate there,
                            // then this behavior can be something thats propped into this component via a function. The function
                            // could take the `elem` and then do whatever's needed.
                            window.scrollTo({
                                behavior: 'auto',
                                top: elem.getBoundingClientRect().top + window.scrollY - 75, // The 75 accounts for the top header height (blue bar with page title on it)
                            });
                        }
                    }}
                >
                    {props.expandableComponent}
                </Collapse>
            )}
        </Box>
    );
};
