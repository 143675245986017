import * as Colors from '@brightlayer-ui/colors';
import { TextField, TextFieldProps } from '@mui/material';

const styles = {
    readOnlyField: {
        marginBottom: '16px',
        marginRight: '50px',
        height: '80px',
        width: '425px',
    },
    button: {
        textTransform: 'uppercase',
        borderRadius: '50px',
        width: '100%',
    },
};

export const FilledTextField: React.FC<Omit<TextFieldProps, 'variant'>> = (props) => {
    const { sx, InputProps, ...otherProps } = props;
    const backgroundColorStyle = { style: { backgroundColor: Colors.gray[50] } };

    return (
        <TextField
            variant="filled"
            sx={[styles.readOnlyField, ...(Array.isArray(sx) ? sx : [sx])]}
            InputProps={InputProps ? { ...InputProps, ...backgroundColorStyle } : backgroundColorStyle}
            {...otherProps}
        />
    );
};
