import { Box } from '@mui/material';
import { useState } from 'react';
import { User } from '../../services/user/types';
import { AccountDisplay } from './AccountDisplay';
import { AccountEdit } from './AccountEdit';

const styles = {
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
    wrapper: {
        paddingLeft: '15px',
        paddingTop: '10px',
    },
    formField: {
        maxWidth: '400px',
        width: '100%',
        marginBottom: 2,
    },
    emailContainer: {
        display: 'flex',
        alignItems: 'baseline',
    },
    tooltip: { marginLeft: '17px' },
    notificationSwitch: { marginBottom: 2 },
    textBox: {
        marginBottom: '5px',
    },
    button: {
        marginTop: '35px',
        marginBottom: '25px',
        textTransform: 'uppercase',
    },
};

type AccountInfoProps = {
    user: User;
};

export const AccountInfo: React.FC<AccountInfoProps> = (props) => {
    const { user } = props;

    const [inEditMode, setInEditMode] = useState(false);

    return (
        <Box sx={styles.wrapper}>
            {inEditMode ? (
                <AccountEdit
                    user={user}
                    exitEditMode={(): void => {
                        setInEditMode(false);
                    }}
                />
            ) : (
                <AccountDisplay
                    user={user}
                    enterEditMode={(): void => {
                        setInEditMode(true);
                    }}
                />
            )}
        </Box>
    );
};
