import { useRegistrationContext, useRegistrationWorkflowContext } from '@brightlayer-ui/react-auth-workflow';
import { WorkflowCardActionsProps } from '@brightlayer-ui/react-auth-workflow/dist/components/WorkflowCard/WorkflowCard.types';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PortalAccountDetailsScreenBase } from './AccountDetailsScreenBase';
import { AccountDetailsPageProps } from './types';
import { AccountDetailsValidations } from './validations';

type Input = React.ChangeEvent<HTMLInputElement>;

export const AccountDetailsPage: React.FC<AccountDetailsPageProps> = (props) => {
    const { inviteCodeScreenData } = props;

    const { t } = useTranslation();
    const { actions } = useRegistrationContext();
    const { nextScreen, previousScreen, currentScreen, totalScreens, screenData } = useRegistrationWorkflowContext();
    const initialDetails = screenData;

    const firstNameLabel = t('bluiCommon:FORMS.FIRST_NAME');
    const lastNameLabel = t('bluiCommon:FORMS.LAST_NAME');
    const companyNameLabel = t('ACCOUNT_DETAILS_PAGE.COMPANY_NAME_LABEL');
    const phoneNumberLabel = t('ACCOUNT_DETAILS_PAGE.PHONE_NUMBER_LABEL');

    const [firstName, setFirstName] = useState(initialDetails.AccountDetails.firstName || '');
    const [lastName, setLastName] = useState(initialDetails.AccountDetails.lastName || '');
    const [phoneNumber, setPhoneNumber] = useState(initialDetails.AccountDetails.extra?.phoneNumber.toString() || '');
    const [companyName, setCompanyName] = useState(initialDetails.AccountDetails.extra?.companyName.toString() || '');

    const onFirstNameInputChange = (text: Input): void => {
        setFirstName(text.target.value);
    };

    const onLastNameInputChange = (text: Input): void => {
        setLastName(text.target.value);
    };

    const onCompanyNameInputChange = (text: Input): void => {
        setCompanyName(text.target.value);
    };

    const onPhoneNumberInputChange = (text: Input): void => {
        setPhoneNumber(text.target.value);
    };

    const email = screenData.CreateAccount.emailAddress;
    const password = screenData.CreatePassword.password;

    const onNext = useCallback(async (): Promise<void> => {
        const extra = {
            email,
            phoneNumber,
            companyName,
        };

        await actions?.setAccountDetails?.({
            firstName,
            lastName,
            extra,
        });

        void nextScreen({
            screenId: 'AccountDetails',
            values: {
                firstName,
                lastName,
                email,
                password,
                phoneNumber,
                companyName,
                inviteCode: inviteCodeScreenData,
            },
        });
    }, [actions, firstName, lastName, phoneNumber, companyName]);

    const onPrevious = useCallback(() => {
        const extra = {
            email,
            phoneNumber,
            companyName,
        };

        previousScreen({
            screenId: 'AccountDetails',
            values: { firstName, lastName, extra },
        });
    }, [firstName, lastName, email, password, phoneNumber, companyName]);

    const workflowCardActionsProps: WorkflowCardActionsProps = {
        canGoNext: !!(firstName && lastName),
        showNext: true,
        showPrevious: true,
        nextLabel: 'Next',
        previousLabel: 'Back',
        totalSteps: totalScreens,
        currentStep: currentScreen,
        onNext: (): void => void onNext(),
        onPrevious: (): void => void onPrevious(),
    };

    return (
        <PortalAccountDetailsScreenBase
            WorkflowCardHeaderProps={{ title: t('ACCOUNT_DETAILS_PAGE.TITLE') }}
            WorkflowCardInstructionProps={{ instructions: t('ACCOUNT_DETAILS_PAGE.INSTRUCTIONS') }}
            WorkflowCardActionsProps={workflowCardActionsProps}
            initialFirstName={firstName}
            initialLastName={lastName}
            initialCompanyName={companyName}
            initialPhoneNumber={phoneNumber}
            firstNameLabel={firstNameLabel}
            firstNameTextFieldProps={{ onChange: onFirstNameInputChange }}
            firstNameValidator={AccountDetailsValidations.firstNameValidator}
            lastNameLabel={lastNameLabel}
            lastNameTextFieldProps={{ onChange: onLastNameInputChange }}
            lastNameValidator={AccountDetailsValidations.lastNameValidator}
            companyNameLabel={companyNameLabel}
            companyNameTextFieldProps={{ onChange: onCompanyNameInputChange }}
            companyNameValidator={AccountDetailsValidations.companyNameValidator}
            phoneNumberLabel={phoneNumberLabel}
            phoneNumberTextFieldProps={{ onChange: onPhoneNumberInputChange }}
            phoneNumberValidator={AccountDetailsValidations.phoneNumberValidator}
        />
    );
};
