import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { SelectableDropdownListItem } from '../../components/SelectableDropdownListItem';
import { UserRole } from '../../services/user/types';
import { roleText } from '../../utils';

const styles = {
    list: {
        marginLeft: '25px',
        marginRight: '25px',
    },
};

export type ListOrganizationsProps = {
    organizations: Array<{
        organizationName: string;
        organizationId: string;
        role: UserRole;
    }>;
};

export const ListOrganizations: React.FC<ListOrganizationsProps> = (props) => {
    const { organizations } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const sorted = [...organizations].sort((a, b) => a.organizationName.localeCompare(b.organizationName));

    return (
        <Box sx={styles.list}>
            {sorted.map((org) => (
                <SelectableDropdownListItem
                    key={org.organizationId}
                    title={org.organizationName}
                    subtitle={roleText(org.role, t)}
                    onClick={(): void => {
                        navigate(`/organizations/${org.organizationId}`);
                    }}
                />
            ))}
        </Box>
    );
};
