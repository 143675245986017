import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

const styles = {
    footer: {
        backgroundColor: '#f0f0f0',
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
        padding: '10px 20px',
        textAlign: 'center',
        width: '100%',
        position: 'sticky',
        bottom: 0,
    },
};

export const Footer: React.FC<PropsWithChildren> = (props) => <Box sx={styles.footer}>{props.children}</Box>;
