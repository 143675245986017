import { EmptyState } from '@brightlayer-ui/react-components';
import { AddCircle, Group } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ErrorState } from '../../components/ErrorState';
import { NavBarButton } from '../../components/NavBarButton';
import { PageWrapper } from '../../components/PageWrapper';
import { Spinner } from '../../components/Spinner';
import { FetchedState, getUserRoles, UserSelectors } from '../../features/userSlice';
import { ListOrganizations } from './ListOrganizations';

const styles = {
    listBox: {
        marginTop: '25px',
    },
    emptyState: {
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    emptyStateIcon: {
        alignItems: 'center',
        fontSize: '140px',
    },
    contentBody: { marginTop: '20px' },
    divider: {
        borderBottomWidth: '2px',
    },
};

export const OrganizationListPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const organizations = useAppSelector(UserSelectors.userOrganizationRoles);
    const fetchedState = useAppSelector(UserSelectors.userOrganizationRolesFetchedState);

    const [showLoader, setShowLoader] = useState(fetchedState !== FetchedState.FETCHED);

    useEffect(() => {
        const fetchUserRoles = async (): Promise<void> => {
            try {
                await dispatch(getUserRoles());
            } finally {
                setShowLoader(false);
            }
        };

        void fetchUserRoles();
    }, []);

    return (
        <PageWrapper title={t('ORGANIZATION_LIST_PAGE.TITLE')} sx={styles.contentBody}>
            <NavBarButton
                startIcon={<AddCircle />}
                buttonId={'create-organization-button'}
                buttonText={t('ORGANIZATION_LIST_PAGE.CREATE_NEW_ORG')}
                onClick={(): void => {
                    navigate('/organizations/create');
                }}
            />
            <Divider sx={styles.divider} />
            {showLoader ? (
                <Spinner />
            ) : fetchedState === FetchedState.FAILED ? (
                <ErrorState />
            ) : organizations.length > 0 ? (
                <Box sx={styles.listBox}>
                    <ListOrganizations organizations={organizations} />
                </Box>
            ) : (
                <EmptyState
                    sx={styles.emptyState}
                    title={t('ORGANIZATION_LIST_PAGE.NO_ORGANIZATIONS_TITLE')}
                    description={t('ORGANIZATION_LIST_PAGE.NO_ORGANIZATIONS_DESCRIPTION')}
                    icon={<Group sx={styles.emptyStateIcon} />}
                />
            )}
        </PageWrapper>
    );
};
