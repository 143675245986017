import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const styles = {
    topButton: {
        marginTop: '10px',
        marginBottom: '20px',
        textTransform: 'uppercase',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
};

export const ScrollToTopButton: React.FC = () => {
    const scrollToTop = (): void =>
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

    const { t } = useTranslation();

    return (
        <Box sx={styles.buttonContainer}>
            <Button
                data-testid="scroll-to-top-button"
                variant="contained"
                color="primary"
                onClick={scrollToTop}
                sx={styles.topButton}
            >
                <Typography variant="body2">{t('HELP_PAGE.SCROLL_TO_TOP_BUTTON')}</Typography>
            </Button>
        </Box>
    );
};
