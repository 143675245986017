import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { z } from 'zod';

export const organizationSchema = {
    activationKey: z.string(),
    organizationName: z.string().min(1).trim(),
    organizationDescription: z.string().min(1).trim(),
    pocName: z.string().min(1).trim(),
    pocEmail: z.string().trim().email(),
    pocPhoneNumber: z
        .string()
        .refine((value) => isPossiblePhoneNumber(value, 'US'))
        .optional(),
};
