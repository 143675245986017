import * as Colors from '@brightlayer-ui/colors';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { Box, List, Typography, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { HashLink as Link } from 'react-router-hash-link';
import { MarkdownContent } from './MarkdownContent';

export const styles = {
    infoListItem: {
        maxHeight: '60px',
        '&:hover': {
            backgroundColor: Colors.blue[50],
        },
    },
    navSectionLink: {
        textDecoration: 'none',
    },
    navSectionText: {
        textDecoration: 'none',
        color: Colors.black[500],
        whiteSpace: 'pre-wrap',
    },
    contentBox: (theme: Theme): object => ({
        padding: '30px 45px 30px 30px',
        width: '85%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    }),
    advancedTopicsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        minHeight: '100vh',
    },
    sideNav: {
        minWidth: '200px',
        width: '15%',
        position: 'sticky',
        height: '100vh',
        top: '64px',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    },
};

export const ScrollableNav = (props: {
    sectionList: Array<{ id: string; title: string }>;
    source: string;
    pageRoute: string;
}): JSX.Element => {
    const [selectedNavSection, setSelectedNavSection] = useState<string>(props.sectionList[0].id);
    const [inViewPort, setInViewPort] = useState<string[]>([]);
    const location = useLocation();

    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (inViewPort.length > 0) {
            // If a specific menu item was selected and it is in view
            // we will highlight that part of the menu.
            if (inViewPort.includes(location.hash.replace('#', ''))) {
                setSelectedNavSection(location.hash.replace('#', ''));
                return;
            }

            const documentHeight = document.body.scrollHeight;
            const currentScroll = window.scrollY + window.innerHeight;
            // If we are close to the bottom of the screen, we will want to
            // ensure we are highlighting bottom menu items.
            if (currentScroll + 100 > documentHeight) {
                // If we are not quite at the bottom of the screen and we have
                // multiple menu items crunched at the bottom, we will choose to
                // highlight the second to last menu item.
                if (inViewPort.length > 1 && !(currentScroll + 50 >= documentHeight)) {
                    setSelectedNavSection(inViewPort[inViewPort.length - 2]);
                    return;
                }
                // We are at the bottom and we will highlight the last menu item.
                setSelectedNavSection(inViewPort[inViewPort.length - 1]);
                return;
            }

            // No special handling here. Highlight the top most menu item that is
            // in view on the DOM.
            setSelectedNavSection(inViewPort[0]);
        }
    }, [inViewPort]);

    // Keep delaying the function until scrolling is finished.
    let timer: NodeJS.Timeout | undefined;
    const delayedSetItemsInView = (): void => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            const headersInView = _.compact(
                props.sectionList.map(({ id }): string | undefined => {
                    const elementBox = document.getElementById(`${id}`)?.getBoundingClientRect();
                    const isInViewport =
                        (elementBox?.top ?? 0) >= 0 &&
                        (elementBox?.left ?? 0) >= 0 &&
                        (elementBox?.bottom ?? 0) <= (window.innerHeight || document.documentElement.clientHeight) &&
                        (elementBox?.right ?? 0) <= (window.innerWidth || document.documentElement.clientWidth);

                    if (!isInViewport) {
                        return undefined;
                    }

                    return id;
                })
            );
            if (_.xor(headersInView, inViewPort).length > 0) {
                setInViewPort(headersInView);
            }
        }, 300);
    };

    useEffect(() => {
        document.addEventListener('scroll', delayedSetItemsInView);
        return (): void => {
            document.removeEventListener('scroll', delayedSetItemsInView);
        };
    }, []);

    const NavSection: React.FC<{ sectionId: string; title: string }> = (navProps) => (
        <InfoListItem
            hidePadding
            title={
                <Box
                    component={Link}
                    data-testid={`${navProps.sectionId}Link`}
                    to={`${props.pageRoute}#${navProps.sectionId}`}
                    sx={styles.navSectionLink}
                    onClick={(): void => setSelectedNavSection(navProps.sectionId)}
                    smooth
                >
                    <Typography variant="body2" sx={styles.navSectionText}>
                        {navProps.title}
                    </Typography>
                </Box>
            }
            statusColor={navProps.sectionId === selectedNavSection ? Colors.blue[500] : undefined}
            sx={styles.infoListItem}
        />
    );

    return (
        <Box sx={styles.advancedTopicsContainer}>
            <Box sx={styles.contentBox}>
                <MarkdownContent source={props.source} pageRoute={props.pageRoute} />
            </Box>
            {!md && (
                <Box data-testid="side-nav" sx={styles.sideNav}>
                    <List>
                        {props.sectionList.map((section, i) => (
                            <div key={i}>
                                <NavSection sectionId={section.id} title={section.title} />
                            </div>
                        ))}
                    </List>
                </Box>
            )}
        </Box>
    );
};
