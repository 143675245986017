import * as Colors from '@brightlayer-ui/colors';
import { Box, Typography } from '@mui/material';

const styles = {
    title: {
        color: Colors.blue['500'],
    },
    content: {
        color: Colors.black['500'],
    },
    textAndTitle: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
    },
};

type TextAndTitleProps = {
    id: string;
    title: string;
    content: string;
};

export const GenericTextAndTitle = (props: TextAndTitleProps): JSX.Element => (
    <Box data-testid={`${props.id}-container`} sx={styles.textAndTitle}>
        <Typography data-testid="generic-text-and-title" id={props.id} variant={'caption'} sx={styles.title}>
            {props.title}
        </Typography>
        <Typography id="generic-text-and-content" aria-labelledby={props.id} variant={'body1'} sx={styles.content}>
            {props.content}
        </Typography>
    </Box>
);
