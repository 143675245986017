import { buildEnergyManagerClient } from '../apiClient';
import {
    AssignOrganizationRoleToUserArgs,
    CreatedOrganization,
    CreateOrganization,
    ExternalApplication,
    GetUsersInOrganization,
    Organization,
    OrganizationSecretWithValue,
    RemoveOrganizationUserArgs,
    RevokeOrgCredentialsFromExternalApplicationArgs,
    ShareOrgCredentialsWithExternalApplicationArgs,
    UpdateOrganization,
} from './types';

const getOrganization = async (organizationId: string): Promise<Organization> => {
    const apiClient = buildEnergyManagerClient();

    const response = await apiClient.get<Organization>(`/api/organizations/${organizationId}`);

    return response.data;
};

const createOrganization = async (organization: CreateOrganization): Promise<CreatedOrganization> => {
    const apiClient = buildEnergyManagerClient();

    const response = await apiClient.post<CreatedOrganization>('/api/organizations', organization);

    return response.data;
};

const updateOrganization = async (organization: UpdateOrganization): Promise<Organization> => {
    const apiClient = buildEnergyManagerClient();

    const response = await apiClient.put<Organization>(`/api/organizations/${organization.id}`, organization);

    return response.data;
};

const shareOrgCredentialsWithExternalApplication = async (
    args: ShareOrgCredentialsWithExternalApplicationArgs
): Promise<void> => {
    const apiClient = buildEnergyManagerClient();

    const body = {
        organizationId: args.organizationId,
    };

    await apiClient.post<undefined>(`/api/external-applications/installer-app/share-credentials`, body);
};

const revokeOrgCredentialsFromExternalApplication = async (
    args: RevokeOrgCredentialsFromExternalApplicationArgs
): Promise<void> => {
    const apiClient = buildEnergyManagerClient();

    await apiClient.delete(`/api/external-applications/installer-app/share-credentials/${args.organizationId}`);
};

const getExternalApplication = async (): Promise<ExternalApplication> => {
    const apiClient = buildEnergyManagerClient();

    const result = await apiClient.get<ExternalApplication>(`/api/external-applications/installer-app`);

    return result.data;
};

const getUsersInOrganization = async (organizationId: string): Promise<GetUsersInOrganization[]> => {
    const apiClient = buildEnergyManagerClient();

    const result = await apiClient.get<GetUsersInOrganization[]>(`/api/organizations/${organizationId}/users`);

    return result.data;
};

const assignOrganizationRoleToUser = async (args: AssignOrganizationRoleToUserArgs): Promise<void> => {
    const apiClient = buildEnergyManagerClient();

    const body = {
        email: args.email ?? undefined,
        userId: args.userId ?? undefined,
        role: args.role,
    };

    return await apiClient.post(`/api/organizations/${args.organizationId}/users`, body);
};

const rotateOrgServiceAccountSecret = async (
    organizationId: string,
    secretName: string
): Promise<OrganizationSecretWithValue> => {
    const apiClient = buildEnergyManagerClient();

    const result = await apiClient.post<OrganizationSecretWithValue>(
        `/api/organizations/${organizationId}/rotate-secret`,
        {
            secretName,
        }
    );

    return result.data;
};

const removeOrganizationUser = async (args: RemoveOrganizationUserArgs): Promise<void> => {
    const apiClient = buildEnergyManagerClient();

    const result = await apiClient.delete(`/api/organizations/${args.organizationId}/users/${args.userId}`);

    return result.data;
};

export const OrganizationService = {
    getOrganization,
    createOrganization,
    updateOrganization,
    shareOrgCredentialsWithExternalApplication,
    revokeOrgCredentialsFromExternalApplication,
    getExternalApplication,
    getUsersInOrganization,
    rotateOrgServiceAccountSecret,
    assignOrganizationRoleToUser,
    removeOrganizationUser,
};
