import { Box, Button, Typography } from '@mui/material';

import * as Colors from '@brightlayer-ui/colors';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { PrivacyTip } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { CopyButton } from '../../components/CopyButton';
import { FilledTextField } from '../../components/FilledTextField';
import { useAlert } from '../../lib/AlertContext';
import { CreatedOrganization } from '../../services/organization/types';
import { formatDate } from '../../utils';

const styles = {
    secretsContent: {
        marginLeft: '25px',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        marginLeft: '25px',
        marginTop: '25px',
    },
    doneButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        marginLeft: '25px',
    },
    title: { marginBottom: '25px' },
    informationWarning: { marginBottom: '6px' },
    apiKeyInformation: { marginBottom: '28px', maxWidth: '700px' },
    textBoxHeader: { marginBottom: '10px' },
    doneButton: { marginTop: '20px', textTransform: 'uppercase' },
    readOnlyTextBox: { marginBottom: '15px' },
    securityInfoBar: {
        marginBottom: '15px',
        backgroundColor: Colors.yellow[50],
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    secretsContainer: {
        backgroundColor: Colors.yellow[100],
        paddingTop: '25px',
    },
    infoContainer: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        paddingRight: '25px',
    },
};

type CreateOrganizationSuccessPageProps = {
    organization: CreatedOrganization;
};

export const CreateOrganizationSuccessPage: React.FC<CreateOrganizationSuccessPageProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showConfirmationToUser } = useAlert();

    const { organization, serviceAccount } = props.organization;

    const onDone = (): void => {
        showConfirmationToUser({
            title: t('CREATE_ORGANIZATION_PAGE.CONFIRM_BEFORE_EXIT_TITLE'),
            description: t('CREATE_ORGANIZATION_PAGE.CONFIRM_BEFORE_EXIT_DESCRIPTION'),
            cancelText: t('COMMON.CANCEL'),
            confirmText: t('COMMON.OK'),
            onConfirm: () => {
                navigate(`/organizations/${organization.id}`);
            },
        });
    };

    return (
        <Box>
            <Box sx={styles.body}>
                <Typography sx={styles.title} variant="h4">
                    {t('CREATE_ORGANIZATION_SUCCESS_PAGE.CREATED_MESSAGE', { organizationName: organization.name })}
                </Typography>
                <Box sx={styles.infoContainer}>
                    <Box>
                        <Typography sx={styles.informationWarning} variant="body1">
                            <b>{t('CREATE_ORGANIZATION_SUCCESS_PAGE.KEEP_SAFE_MESSAGE')}</b>
                        </Typography>
                        <Typography sx={styles.apiKeyInformation} variant="body1">
                            {t('CREATE_ORGANIZATION_SUCCESS_PAGE.BODY')}
                        </Typography>
                        <Typography sx={styles.textBoxHeader} variant="body1">
                            <b>{t('CREATE_ORGANIZATION_SUCCESS_PAGE.YOUR_CLIENT_ID')}</b>
                        </Typography>
                        <FilledTextField
                            label={t('CREATE_ORGANIZATION_SUCCESS_PAGE.YOUR_CLIENT_ID')}
                            defaultValue={serviceAccount.clientId}
                            InputProps={{
                                readOnly: true,
                                endAdornment: <CopyButton content={serviceAccount.clientId} />,
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.secretsContainer}>
                <Box sx={styles.secretsContent}>
                    <Typography sx={styles.textBoxHeader} variant="body1">
                        <b>{t('CREATE_ORGANIZATION_SUCCESS_PAGE.YOUR_SECRETS')}</b>
                    </Typography>
                    <InfoListItem
                        title={t('CREATE_ORGANIZATION_SUCCESS_PAGE.COPY_INSTRUCTIONS')}
                        subtitle={t('CREATE_ORGANIZATION_SUCCESS_PAGE.WARNING')}
                        divider={'full'}
                        statusColor={Colors.yellow[500]}
                        subtitleSeparator={'/'}
                        icon={<PrivacyTip />}
                        sx={styles.securityInfoBar}
                    />
                    {serviceAccount.clientSecrets.map(
                        (secret): JSX.Element => (
                            <Box key={secret.secret}>
                                <Typography sx={styles.textBoxHeader} variant="body2">
                                    <i>
                                        {t('CREATE_ORGANIZATION_SUCCESS_PAGE.EXPIRES_ON', {
                                            date: formatDate(DateTime.fromISO(secret.expiration)),
                                        })}
                                    </i>
                                </Typography>
                                <FilledTextField
                                    label={secret.name}
                                    defaultValue={secret.secret}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: <CopyButton content={secret.secret} />,
                                    }}
                                />
                            </Box>
                        )
                    )}
                </Box>
            </Box>
            <Box sx={styles.doneButtonContainer}>
                <Button
                    data-testid="done-button"
                    sx={styles.doneButton}
                    variant="contained"
                    color="primary"
                    onClick={onDone}
                >
                    {t('COMMON.DONE')}
                </Button>
            </Box>
        </Box>
    );
};
