export const Logger = {
    info: (message: string): void => {
        if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log(message);
        }
    },
    error: (message: string): void => {
        if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error(message);
        }
    },
};
