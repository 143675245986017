import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
} from '@mui/material';

const styles = {
    actionsContainer: { display: 'flex', justifyContent: 'space-between' },
    leftActions: { display: 'flex', alignItems: 'center' },
    rightActions: { display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto' },
    button: { margin: '12px' },
    contentContainer: { margin: '0' },
    circularProgress: {
        color: 'white',
    },
};

type GenericModalProps = {
    modalTestId: string;
    title: string;
    cancelText?: string;
    confirmText: string;
    setShowModal?: (show: boolean) => void;
    confirmOnClick: () => Promise<void> | void;
    disable?: boolean;
    loading?: boolean;
} & React.PropsWithChildren;

export const GenericModal: React.FC<GenericModalProps> = (props) => {
    const { modalTestId, title, children, confirmText, confirmOnClick, cancelText, setShowModal, disable, loading } =
        props;

    return (
        <div>
            <Dialog
                open={true}
                data-testid={modalTestId}
                aria-labelledby="dialog-title"
                transitionDuration={{ exit: 0, enter: 30 }}
            >
                <DialogTitle data-testid="dialog-title">{title}</DialogTitle>
                <DialogContent sx={styles.contentContainer}>{children}</DialogContent>
                <Divider />
                <DialogActions sx={styles.actionsContainer}>
                    {cancelText && (
                        <Box sx={styles.leftActions}>
                            <Button
                                data-testid="generic-modal-cancel-button"
                                color="primary"
                                variant="outlined"
                                onClick={(): void => {
                                    if (setShowModal) {
                                        setShowModal(false);
                                    }
                                }}
                                sx={styles.button}
                            >
                                {cancelText}
                            </Button>
                        </Box>
                    )}
                    <Box sx={styles.rightActions}>
                        <Button
                            data-testid={`${modalTestId}-button`}
                            color="primary"
                            variant="contained"
                            sx={styles.button}
                            onClick={(): void => {
                                void confirmOnClick();
                            }}
                            disabled={disable}
                        >
                            {loading ? (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularProgress
                                        size={24}
                                        sx={{ marginRight: 1 }}
                                        color="inherit"
                                        data-testid={`${modalTestId}-spinner`}
                                    />
                                </Box>
                            ) : (
                                confirmText
                            )}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
};
