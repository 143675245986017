import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from '../../components/PageWrapper';
import { ScrollableNav } from '../../components/ScrollableNav';
import source from '../../markdown-content/help.english.md';

const HELP = (t: TFunction): Array<{ id: string; title: string }> => [
    {
        id: 'registration',
        title: t('HELP_PAGE.REGISTRATION_TITLE'),
    },
    {
        id: 'organization',
        title: t('HELP_PAGE.ORGANIZATION_TITLE'),
    },
    {
        id: 'createAnOrganization',
        title: t('HELP_PAGE.CREATE_AN_ORG_TITLE'),
    },
    {
        id: 'contactUs',
        title: t('HELP_PAGE.CONTACT_US_TITLE'),
    },
];

export const HelpPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <PageWrapper title={t('HELP_PAGE.TITLE')}>
            <ScrollableNav sectionList={HELP(t)} source={source} pageRoute="/help" />
        </PageWrapper>
    );
};
