import { Box, SxProps, Theme } from '@mui/material';

const Styles = {
    width: 104,
    height: 28,
};
export const EatonLogo = (props: { sx?: SxProps<Theme> }): JSX.Element => (
    <Box
        component="svg"
        sx={{ ...props.sx, ...Styles }}
        viewBox="0 0 104 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M33.6649 5.37484L36.1786 14.3233H30.9638L33.6649 5.37484ZM29.0793 20.5814L26.785 28H40.1936L38.031 20.5814H29.0793ZM64.0698 0H38.3324V7.96701H46.4531V28H59.5193C58.7087 27.5064 53.1691 23.1678 53.1691 15.0652C53.1837 8.32784 57.3099 1.73196 64.0698 0ZM68.5296 20.821C71.5642 20.821 74.0048 18.3357 74.0048 15.1922C74.0048 12.0487 71.7193 9.24288 68.6378 9.24288C65.5564 9.24288 63.1333 12.0833 63.1333 15.2037C63.1333 18.3241 65.413 20.8326 68.5413 20.8326L68.5296 20.821ZM95.5223 17.6082L90.2958 0H76.9224V28H85.2771V10.4553H85.3356L90.5124 28H104V0H95.575V17.6082H95.5223ZM16.1886 28L18.6 20.1485H9.64822V17.7814H19.3345L21.6317 10.2561H9.64822V7.97856H22.2989L24.7395 0H0V28H16.1886Z"
            fill="#727E84"
        />
    </Box>
);
