import * as Colors from '@brightlayer-ui/colors';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';

const styles = {
    card: {
        flex: 1,
        border: `1px solid ${Colors.black[50]}`,
    },
    cardIconContainer: {
        color: Colors.blue[500],
        fontSize: '5em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '16px',
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        maxWidth: '400px',
    },
    cardTextWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'left',
    },
    titleText: {
        color: Colors.blue[500],
        textAlign: 'left',
    },
    bodyText: {
        color: Colors.black[500],
        textAlign: 'left',
    },
};

type IconCardProps = {
    title: string;
    body: string;
    icon: JSX.Element;
    onClick: () => void;
};

export const IconCard: React.FC<IconCardProps> = (props) => {
    const { title, body, icon, onClick } = props;

    return (
        <Card sx={styles.card}>
            <CardActionArea sx={styles.contentWrapper} onClick={onClick}>
                <CardMedia sx={styles.cardIconContainer}>{icon}</CardMedia>
                <CardContent sx={styles.cardTextWrapper}>
                    <Typography sx={styles.titleText} variant="h6">
                        {title}
                    </Typography>
                    <Typography sx={styles.bodyText}>{body}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};
