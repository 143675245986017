import { Box, Button, Divider, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChangePasswordButton } from '../../components/ChangePasswordButton';
import { GenericTextAndTitle } from '../../components/GenericTextAndTitle';
import { User } from '../../services/user/types';

const styles = {
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
    textBox: {
        marginBottom: '5px',
    },
    button: {
        marginTop: '35px',
        marginBottom: '25px',
        textTransform: 'uppercase',
    },
    buttonContainer: (theme: Theme): object => ({
        paddingLeft: theme.spacing(1),
    }),
    divider: {
        marginBottom: '25px',
    },
};

type AccountDisplayProps = {
    user: User;
    enterEditMode: () => void;
};

export const AccountDisplay: React.FC<AccountDisplayProps> = (props) => {
    const { user, enterEditMode } = props;

    const { t } = useTranslation();

    return (
        <>
            <Box sx={styles.textContainer}>
                <Box sx={styles.textBox}>
                    <GenericTextAndTitle
                        id={'firstName'}
                        title={t('ACCOUNT_INFO_PAGE.FIRST_NAME_LABEL')}
                        content={user.firstName}
                    />
                </Box>
                <Box sx={styles.textBox}>
                    <GenericTextAndTitle
                        id={'lastName'}
                        title={t('ACCOUNT_INFO_PAGE.LAST_NAME_LABEL')}
                        content={user.lastName}
                    />
                </Box>
                <Box sx={styles.textBox}>
                    <GenericTextAndTitle id={'email'} title={t('ACCOUNT_INFO_PAGE.EMAIL_LABEL')} content={user.email} />
                </Box>
                <Box sx={styles.textBox}>
                    <GenericTextAndTitle
                        id={'phoneNumber'}
                        title={t('ACCOUNT_INFO_PAGE.PHONE_NUMBER_LABEL')}
                        content={user.phoneNumber ?? ''}
                    />
                </Box>
                <Box sx={styles.textBox}>
                    <GenericTextAndTitle
                        id={'company'}
                        title={t('ACCOUNT_INFO_PAGE.COMPANY_NAME_LABEL')}
                        content={user.company ?? ''}
                    />
                </Box>
            </Box>
            <Button
                variant="outlined"
                color="primary"
                onClick={enterEditMode}
                sx={styles.button}
                data-testid="edit-account-info-button"
            >
                {t('COMMON.EDIT')}
            </Button>
            <Divider sx={styles.divider} />
            <Box sx={styles.buttonContainer}>
                <ChangePasswordButton />
            </Box>
        </>
    );
};
