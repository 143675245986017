import { Box, Button, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RegenerateConfirmationModal } from '../pages/OrganizationDetails/RegenerateConfirmationModal';
import { OrganizationSecret } from '../services/organization/types';

const styles = {
    regenerateButton: {
        textTransform: 'uppercase',
        borderRadius: '50px',
        width: '100%',
    },
};

type RegenerateButtonProps = {
    secretName: string;
    organizationName: string;
    organizationId: string;
    setSecret: (updatedSecret: OrganizationSecret) => void;
    isOrgRegisteredWithInstallerApp: boolean;
};

export const RegenerateButton: React.FC<RegenerateButtonProps> = (props) => {
    const { secretName, organizationName, organizationId, setSecret, isOrgRegisteredWithInstallerApp } = props;
    const { t } = useTranslation();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    return (
        <Box>
            <InputAdornment position="end">
                <Button
                    data-testid={`regenerate-${secretName}-button`}
                    sx={styles.regenerateButton}
                    variant="contained"
                    color="primary"
                    onClick={(): void => {
                        setShowConfirmationModal(true);
                    }}
                >
                    {t('ORGANIZATION_DETAILS_PAGE.REGENERATE_BUTTON')}
                </Button>
            </InputAdornment>
            {showConfirmationModal && (
                <RegenerateConfirmationModal
                    secretName={secretName}
                    organizationName={organizationName}
                    organizationId={organizationId}
                    setShowConfirmationModal={setShowConfirmationModal}
                    setSecret={setSecret}
                    isOrgRegisteredWithInstallerApp={isOrgRegisteredWithInstallerApp}
                />
            )}
        </Box>
    );
};
