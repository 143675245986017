import { LoginScreen } from '@brightlayer-ui/react-auth-workflow';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router';

const styles = {
    image: {
        width: '273px',
    },
};

export const LoginWorkflow = (): JSX.Element => {
    const navigate = useNavigate();

    return (
        <LoginScreen
            onForgotPassword={(): void => {
                navigate('/forgot-password');
            }}
            projectImage={<Box component="img" src="/images/emcb_stacked_logo05x.png" sx={styles.image} />}
        />
    );
};
