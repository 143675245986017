export type LoginResponse = {
    id: string;
    authToken: LoginToken;
};

export type User = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    company?: string;
};

export type CreateUser = {
    inviteCode: string;
    password?: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    company?: string;
};

export type RefreshUserToken = {
    refreshToken: string;
    accessToken: string;
};

export type LoginToken = {
    accessToken: string;
    expiresAt: string;
    refreshToken: string;
};

export enum UserRole {
    OWNER = 'owner',
    DEVELOPER = 'developer',
}

export type GetUserRolesResponse = {
    organizationId: string;
    organizationName: string;
    role: UserRole;
};

export type ValidateRegistrationRequest = {
    email: string;
    inviteCode: string;
};

export type ValidateRegistrationResponse = {
    hasEatonAccount: boolean;
};

export type UpdateUser = {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    company?: string;
};

export type ChangePassword = {
    id: string;
    currentPassword: string;
    newPassword: string;
};

export type IUserService = {
    login: (email: string, password: string) => Promise<LoginResponse>;
    logout: (userId: string) => Promise<void>;
    getUser: (userId: string, token: string) => Promise<User>;
    registerUser: (args: CreateUser) => Promise<User>;
    refreshAccessToken: (accessToken: string, refreshToken: string) => Promise<LoginToken>;
    getUserRoles: (userId: string) => Promise<GetUserRolesResponse[]>;
    validateRegistration: (args: ValidateRegistrationRequest) => Promise<ValidateRegistrationResponse>;
    updateUser: (args: UpdateUser) => Promise<User>;
    changePassword: (args: ChangePassword) => Promise<void>;
    forgotPassword: (email: string) => Promise<void>;
};
