// Leaving as JSX.Element because no props and React.FC implies the icon could have children
// Replace with a FC type that doesnt imply children when there is time
export const UserSecurityIcon = (): JSX.Element => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.33333 5.33333C9.56 5.33333 9.78 5.36 10 5.39333V2.18L5 0L0 2.18L0 5.45333C0 8.48 2.13333 11.3133 5 12C5.36667 11.9133 5.72 11.7867 6.06667 11.6333C5.60667 10.98 5.33333 10.1867 5.33333 9.33333C5.33333 7.12667 7.12667 5.33333 9.33333 5.33333Z"
            fill="white"
        />
        <path
            d="M9.33333 6.66667C7.86 6.66667 6.66667 7.86 6.66667 9.33333C6.66667 10.8067 7.86 12 9.33333 12C10.8067 12 12 10.8067 12 9.33333C12 7.86 10.8067 6.66667 9.33333 6.66667ZM9.33333 7.58667C9.74667 7.58667 10.08 7.92667 10.08 8.33333C10.08 8.74 9.74 9.08 9.33333 9.08C8.92667 9.08 8.58667 8.74 8.58667 8.33333C8.58667 7.92667 8.92 7.58667 9.33333 7.58667ZM9.33333 11.1667C8.71333 11.1667 8.17333 10.86 7.84 10.3867C7.87333 9.90667 8.84667 9.66667 9.33333 9.66667C9.82 9.66667 10.7933 9.90667 10.8267 10.3867C10.4933 10.86 9.95333 11.1667 9.33333 11.1667Z"
            fill="white"
        />
    </svg>
);
