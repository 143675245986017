import { EmptyState } from '@brightlayer-ui/react-components';
import { Group } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useAppSelector } from '../../app/hooks';
import { ErrorState } from '../../components/ErrorState';
import { PageWrapper } from '../../components/PageWrapper';
import { Spinner } from '../../components/Spinner';
import { UserSelectors } from '../../features/userSlice';
import { useAlert } from '../../lib/AlertContext';
import { OrganizationService } from '../../services/organization/organization.service';
import { GetUsersInOrganization, Organization } from '../../services/organization/types';
import { UserRole } from '../../services/user/types';
import { EditOrganizationForm } from './EditOrganizationForm';
import { Logger } from '../../logger';

const styles = {
    emptyState: {
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    emptyStateIcon: {
        alignItems: 'center',
        fontSize: '140px',
    },
};

export const EditOrganizationPage: React.FC = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { showErrorToUser } = useAlert();
    const navigate = useNavigate();

    const [organization, setOrganization] = useState<Organization>();
    const [usersInOrg, setUsersInOrg] = useState<GetUsersInOrganization[]>([]);
    const [showError, setShowError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const orgId = params.id ?? '';

    const currentUserId = useAppSelector((state) => UserSelectors.userDetails(state))?.id;
    const userRole = usersInOrg ? usersInOrg.find((user) => user.id === currentUserId)?.role : undefined;

    useEffect(() => {
        const fetchOrganizationDetailsData = async (): Promise<void> => {
            try {
                setIsLoading(true);
                const [fetchedOrg, fetchedUsers] = await Promise.all([
                    OrganizationService.getOrganization(orgId),
                    OrganizationService.getUsersInOrganization(orgId),
                ]);
                setOrganization(fetchedOrg);
                setUsersInOrg(fetchedUsers);

                setShowError(false);
            } catch (error) {
                Logger.error(String(error));
                setShowError(true);
            } finally {
                setIsLoading(false);
            }
        };

        void fetchOrganizationDetailsData();
    }, [orgId]);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!userRole || !currentUserId) {
            setShowError(true);
            return;
        }

        setShowError(false);

        const validateUserAccess = (): void => {
            if (userRole && currentUserId && userRole !== UserRole.OWNER) {
                showErrorToUser({
                    errorTitle: t('EDIT_ORGANIZATION_PAGE.ACCESS_DENIED_TITLE'),
                    errorDescription: t('EDIT_ORGANIZATION_PAGE.ACCESS_DENIED_DESCRIPTION'),
                    confirmText: t('COMMON.OK'),
                    onConfirm: () => {
                        navigate(`/organizations/${orgId}`);
                    },
                });
            }
        };

        validateUserAccess();
    }, [currentUserId, userRole, orgId, isLoading, showErrorToUser, navigate]);

    return (
        <PageWrapper title={t('EDIT_ORGANIZATION_PAGE.HEADER_TITLE')}>
            {isLoading ? (
                <Spinner />
            ) : showError ? (
                <ErrorState />
            ) : organization ? (
                <EditOrganizationForm organization={organization} />
            ) : (
                <EmptyState
                    sx={styles.emptyState}
                    title={t('EDIT_ORGANIZATION_PAGE.NO_ORGANIZATIONS_TITLE')}
                    description={t('EDIT_ORGANIZATION_PAGE.NO_ORGANIZATIONS_DESCRIPTION')}
                    icon={<Group sx={styles.emptyStateIcon} />}
                />
            )}
        </PageWrapper>
    );
};
