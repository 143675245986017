import { accountInfoSchemas } from '../../../lib/schemas';
import i18n from '../../../translations/i18n';

export const AccountDetailsValidations = {
    firstNameValidator: (firstName: string): boolean | string =>
        accountInfoSchemas.firstName.safeParse(firstName).success ||
        i18n.t('VALIDATION_ERRORS.ACCOUNT_DETAILS.FIRST_NAME_ERROR'),

    lastNameValidator: (lastName: string): boolean | string =>
        accountInfoSchemas.lastName.safeParse(lastName).success ||
        i18n.t('VALIDATION_ERRORS.ACCOUNT_DETAILS.LAST_NAME_ERROR'),

    companyNameValidator: (companyName?: string): boolean | string =>
        accountInfoSchemas.companyName.safeParse(companyName).success ||
        i18n.t('VALIDATION_ERRORS.ACCOUNT_DETAILS.COMPANY_NAME_ERROR'),

    phoneNumberValidator: (phoneNumber?: string): boolean | string =>
        accountInfoSchemas.phoneNumber.safeParse(phoneNumber).success ||
        i18n.t('VALIDATION_ERRORS.ACCOUNT_DETAILS.PHONE_NUMBER_ERROR'),
};
