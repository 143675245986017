import { Button, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';

const styles = {
    copyButton: {
        textTransform: 'uppercase',
        borderRadius: '50px',
        width: '100%',
    },
};

type CopyButtonProps = {
    content: string;
};

export const CopyButton: React.FC<CopyButtonProps> = (props) => {
    const { t } = useTranslation();

    return (
        <InputAdornment position="end">
            <Button
                sx={styles.copyButton}
                variant="contained"
                color="primary"
                onClick={(): void => {
                    void navigator.clipboard.writeText(props.content);
                }}
            >
                {t('COMMON.COPY')}
            </Button>
        </InputAdornment>
    );
};
