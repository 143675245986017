import { AccountDetails, RegistrationUIActions } from '@brightlayer-ui/react-auth-workflow';
import { AxiosError, HttpStatusCode } from 'axios';
import i18n from 'i18next';
import { z } from 'zod';
import { Logger } from '../logger';
import { UserService } from '../services/user/user.service';

const parseUserData = (
    userData: object
): {
    email: string;
    inviteCode: string;
    password?: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    companyName?: string;
} => {
    const schema = z.object({
        email: z.string().trim(),
        inviteCode: z.string().trim(),
        password: z
            .string()
            .transform((value) => (value === '' ? undefined : value))
            .optional(),
        firstName: z.string().trim().min(1).max(32),
        lastName: z.string().trim().min(1).max(32),
        phoneNumber: z
            .string()
            .trim()
            .transform((value) => (value === '' ? undefined : value))
            .optional(),
        companyName: z
            .string()
            .trim()
            .transform((value) => (value === '' ? undefined : value))
            .optional(),
    });
    const result = schema.safeParse(userData);

    if (result.success) {
        return result.data;
    }

    throw new Error('Failed to register user');
};

/**
 * Example implementation of [[RegistrationUIActions]] to start with during development.
 *
 * Registration Actions to be performed based on the user's actions. The application will create appropriate actions
 * (often API calls, local network storage, credential updates, etc.) based on the actionable needs of the user.
 */
export const ProjectRegistrationUIActions: () => RegistrationUIActions = () => ({
    /**
     * The user wants to complete an action but must first accept the EULA.
     * The application should retrieve an application-specific EULA for the user.
     *
     * @param language  The i18n language the user is requesting for the EULA text.
     *
     * @returns Resolve with EULA, otherwise reject with an error message.
     */
    loadEula: async (): Promise<string> => {
        const response = await fetch('/eaton-developer-portal-eula.html');

        if (!response.ok) {
            throw new Error(`Failed to fetch HTML: ${response.statusText}`);
        }

        const htmlContent = await response.text();
        return `<div>${htmlContent}</div>`;
    },

    /**
     * The user accepted the EULA.
     * The API should now update accepted EULA.
     *
     *
     * @returns Resolve when the server accepted the request.
     */
    acceptEula: async (): Promise<void> => {
        Logger.info('acceptEula');
        await Promise.resolve();
    },

    requestRegistrationCode: async (email: string): Promise<string> => {
        Logger.info(`requestRegistrationCode, email=${email}`);
        return await Promise.resolve('');
    },

    createPassword: async (password: string): Promise<boolean> => {
        Logger.info(`createPassword, password=${password}`);
        return await Promise.resolve(true);
    },

    setAccountDetails: async (details: AccountDetails): Promise<boolean> => {
        Logger.info(`setAccountDetails, details=${JSON.stringify(details)}`);
        return await Promise.resolve(true);
    },
    /**
     * The user has tapped on an email link inviting them to register with the application.
     * The application should validate the code provided by the link.
     *
     * @param validationCode  Registration code provided from the link.
     * @param validationEmail  Email provided from the invitation email link (optional) `?email=addr%40domain.com`.
     *
     * @returns Resolves when the code is valid. True if registration is complete, False if account information is needed.
     *          If the code is not valid a rejection will occur with an error message.
     */
    validateUserRegistrationRequest: async (
        validationCode: string,
        validationEmail?: string
    ): Promise<{ codeValid: boolean | string; accountExists?: boolean }> => {
        if (!validationEmail) {
            throw new Error(i18n.t('ACCOUNT_DETAILS_PAGE.MISSING_EMAIL_ERROR'));
        }

        Logger.info(
            `validateUserRegistrationRequest, validationCode=${validationCode}, validationEmail=${validationEmail}`
        );
        return await Promise.resolve({ codeValid: true, accountExists: false });
    },

    completeRegistration: async (userData: object): Promise<{ email: string; organizationName: string }> => {
        const parsedUserData = parseUserData(userData);

        try {
            await UserService.registerUser({
                email: parsedUserData.email,
                password: parsedUserData.password,
                firstName: parsedUserData.firstName,
                lastName: parsedUserData.lastName,
                inviteCode: parsedUserData.inviteCode,
                company: parsedUserData.companyName,
                phoneNumber: parsedUserData.phoneNumber,
            });

            return { email: parsedUserData.email, organizationName: '' };
        } catch (e) {
            if (e instanceof AxiosError) {
                if (e.response?.status === HttpStatusCode.Conflict) {
                    throw new Error(i18n.t('ACCOUNT_DETAILS_PAGE.USER_ALREADY_EXISTS_ERROR'));
                } else {
                    throw new Error(i18n.t('ACCOUNT_DETAILS_PAGE.BAD_REQUEST_ERROR'));
                }
            }

            throw e;
        }
    },
});
