import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageWrapper } from '../../components/PageWrapper';
import { CreatedOrganization } from '../../services/organization/types';
import { ActivationCodeModal } from './ActivationCodeModal';
import { CreateOrganizationForm } from './CreateOrganizationForm';
import { CreateOrganizationSuccessPage } from './CreateOrganizationSuccessPage';

const styles = {
    contentBody: { marginTop: '20px' },
};

export const CreateOrganizationPage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [activationKey, setActivationKey] = useState<string>();
    const [createdOrganization, setCreatedOrganization] = useState<CreatedOrganization>();

    const onClickUse = (key: string): void => {
        setActivationKey(key);
    };

    const onClickCancel = (): void => {
        navigate('/organizations');
    };

    return (
        <PageWrapper title={t('CREATE_ORGANIZATION_PAGE.TITLE')} sx={styles.contentBody}>
            {!activationKey ? (
                <ActivationCodeModal onClickUse={onClickUse} onClickCancel={onClickCancel} />
            ) : !createdOrganization ? (
                <CreateOrganizationForm activationKey={activationKey} setCreatedOrganization={setCreatedOrganization} />
            ) : (
                <CreateOrganizationSuccessPage organization={createdOrganization} />
            )}
        </PageWrapper>
    );
};
