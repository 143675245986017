import { organizationSchema } from './CreateOrganization/schema';

export const OrganizationFormValidations = {
    activationKeyValidator: (activationKey: string): boolean =>
        organizationSchema.activationKey.safeParse(activationKey).success,

    organizationNameValidator: (organizationName: string): boolean =>
        organizationSchema.organizationName.safeParse(organizationName).success,

    organizationDescriptionValidator: (description: string): boolean =>
        organizationSchema.organizationDescription.safeParse(description).success,

    pointOfContactNameValidator: (pocName: string): boolean => organizationSchema.pocName.safeParse(pocName).success,

    pointOfContactEmailValidator: (pocEmail: string): boolean =>
        organizationSchema.pocEmail.safeParse(pocEmail).success,

    pointOfContactPhoneNumberValidator: (pocPhoneNumber?: string): boolean =>
        organizationSchema.pocPhoneNumber.safeParse(pocPhoneNumber).success,
};
