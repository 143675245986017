import { EmptyState } from '@brightlayer-ui/react-components';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type DisplayErrorProps = {
    errorTitle?: string;
    errorMessage?: string;
    sx?: SxProps<Theme>;
};
export const DisplayError = (props: DisplayErrorProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <Box data-testid="portal-page-error" sx={props.sx}>
            <EmptyState
                title={props.errorTitle ?? t('COMMON.ERROR')}
                description={props.errorMessage ?? t('COMMON.DISPLAY_ERROR_MESSAGE')}
                icon={<ErrorIcon color="error" height={'96px'} width={'96px'} fontSize="inherit" />}
            />
        </Box>
    );
};
