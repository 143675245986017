import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ErrorState } from '../../components/ErrorState';
import { PageWrapper } from '../../components/PageWrapper';
import { Spinner } from '../../components/Spinner';
import { getUserDetails, UserSelectors } from '../../features/userSlice';
import { AccountInfo } from './AccountInfo';

export const AccountInfoPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const userDetails = useAppSelector(UserSelectors.userDetails);

    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchUserDetails = async (): Promise<void> => {
            const result = await dispatch(getUserDetails());
            if (getUserDetails.rejected.match(result)) {
                setError(true);
            }
        };

        void fetchUserDetails();
    }, []);

    return (
        <PageWrapper title={t('ACCOUNT_INFO_PAGE.TITLE')} userProfile={true}>
            {error ? <ErrorState /> : !userDetails ? <Spinner /> : <AccountInfo user={userDetails} />}
        </PageWrapper>
    );
};
