import {
    WorkflowCard,
    WorkflowCardActions,
    WorkflowCardBody,
    WorkflowCardHeader,
    WorkflowCardInstructions,
} from '@brightlayer-ui/react-auth-workflow';
import ErrorManager from '@brightlayer-ui/react-auth-workflow/dist/components/Error/ErrorManager';
import TextField from '@mui/material/TextField';
import React, { useCallback, useEffect, useRef } from 'react';
import { PortalAccountDetailsScreenProps } from './types';

export const PortalAccountDetailsScreenBase: React.FC<PortalAccountDetailsScreenProps> = (props) => {
    const {
        firstNameLabel,
        initialFirstName,
        firstNameValidator = (): void => {},
        firstNameTextFieldProps,
        lastNameLabel,
        initialLastName,
        lastNameValidator = (): void => {},
        lastNameTextFieldProps,
        errorDisplayConfig,
        initialCompanyName,
        companyNameLabel,
        companyNameTextFieldProps,
        companyNameValidator = (): void => {},
        initialPhoneNumber,
        phoneNumberLabel,
        phoneNumberTextFieldProps,
        phoneNumberValidator = (): void => {},
        ...otherProps
    } = props;

    const cardBaseProps = props.WorkflowCardBaseProps || {};
    const headerProps = props.WorkflowCardHeaderProps || {};
    const instructionsProps = props.WorkflowCardInstructionProps || {};
    const actionsProps = props.WorkflowCardActionsProps || {};

    const firstNameRef = useRef<any>(null);
    const lastNameRef = useRef<any>(null);
    const companyNameRef = useRef<any>(null);
    const phoneNumberRef = useRef<any>(null);

    const [firstNameInput, setFirstNameInput] = React.useState(initialFirstName ? initialFirstName : '');
    const [lastNameInput, setLastNameInput] = React.useState(initialLastName ? initialLastName : '');
    const [companyNameInput, setCompanyNameInput] = React.useState(initialCompanyName ? initialCompanyName : '');
    const [phoneNumberInput, setPhoneNumberInput] = React.useState(initialPhoneNumber ? initialPhoneNumber : '');

    const [isFirstNameValid, setIsFirstNameValid] = React.useState(false);
    const [isLastNameValid, setIsLastNameValid] = React.useState(false);

    // These are defaulted to valid as they are not required inputs
    const [isCompanyNameValid, setIsCompanyNameValid] = React.useState(true);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = React.useState(true);

    const [firstNameError, setFirstNameError] = React.useState('');
    const [lastNameError, setLastNameError] = React.useState('');
    const [companyNameError, setCompanyNameError] = React.useState('');
    const [phoneNumberError, setPhoneNumberError] = React.useState('');

    const [shouldValidateFirstName, setShouldValidateFirstName] = React.useState(false);
    const [shouldValidateLastName, setShouldValidateLastName] = React.useState(false);
    const [shouldValidateCompanyName, setShouldValidateCompanyName] = React.useState(false);
    const [shouldValidatePhoneNumber, setShouldValidatePhoneNumber] = React.useState(false);

    const handleFirstNameInputChange = useCallback(
        (firstName: string) => {
            setFirstNameInput(firstName);
            const firstNameValidatorResponse = firstNameValidator(firstName);

            setIsFirstNameValid(typeof firstNameValidatorResponse === 'boolean' ? firstNameValidatorResponse : false);
            setFirstNameError(typeof firstNameValidatorResponse === 'string' ? firstNameValidatorResponse : '');
        },
        [firstNameValidator]
    );

    const handleLastNameInputChange = useCallback(
        (lastName: string) => {
            setLastNameInput(lastName);
            const lastNameValidatorResponse = lastNameValidator(lastName);

            setIsLastNameValid(typeof lastNameValidatorResponse === 'boolean' ? lastNameValidatorResponse : false);
            setLastNameError(typeof lastNameValidatorResponse === 'string' ? lastNameValidatorResponse : '');
        },
        [lastNameValidator]
    );

    const handleCompanyNameInputChange = useCallback(
        (companyName: string) => {
            setCompanyNameInput(companyName);

            // When there is inputted text, validate. Otherwise, set the input to valid and the error to falsey
            if (companyName.length > 0) {
                const companyNameValidatorResponse = companyNameValidator(companyName);

                setIsCompanyNameValid(
                    typeof companyNameValidatorResponse === 'boolean' ? companyNameValidatorResponse : false
                );
                setCompanyNameError(
                    typeof companyNameValidatorResponse === 'string' ? companyNameValidatorResponse : ''
                );
            } else {
                setIsCompanyNameValid(true);
                setCompanyNameError('');
            }
        },
        [companyNameValidator]
    );

    const handlePhoneNumberInputChange = useCallback(
        (phoneNumber: string) => {
            setPhoneNumberInput(phoneNumber);

            // When there is inputted text, validate. Otherwise, set the input to valid and the error to falsey
            if (phoneNumber.length > 0) {
                const phoneNumberValidatorResponse = phoneNumberValidator(phoneNumber);

                setIsPhoneNumberValid(
                    typeof phoneNumberValidatorResponse === 'boolean' ? phoneNumberValidatorResponse : false
                );
                setPhoneNumberError(
                    typeof phoneNumberValidatorResponse === 'string' ? phoneNumberValidatorResponse : ''
                );
            } else {
                setIsPhoneNumberValid(true);
                setPhoneNumberError('');
            }
        },
        [phoneNumberValidator]
    );

    useEffect(() => {
        if (firstNameInput.length > 0) {
            setShouldValidateFirstName(true);
            handleFirstNameInputChange(firstNameInput);
        }
        if (lastNameInput.length > 0) {
            setShouldValidateLastName(true);
            handleLastNameInputChange(lastNameInput);
        }
        if (companyNameInput.length > 0) {
            setShouldValidateCompanyName(true);
            handleCompanyNameInputChange(companyNameInput);
        }
        if (phoneNumberInput.length > 0) {
            setShouldValidatePhoneNumber(true);
            handlePhoneNumberInputChange(phoneNumberInput);
        }
    }, []);

    return (
        <WorkflowCard {...cardBaseProps} {...otherProps}>
            <WorkflowCardHeader {...headerProps} />
            <WorkflowCardInstructions {...instructionsProps} />
            <WorkflowCardBody>
                <ErrorManager {...errorDisplayConfig}>
                    <TextField
                        id="first"
                        fullWidth
                        variant="filled"
                        label={firstNameLabel}
                        value={firstNameInput}
                        required={true}
                        error={shouldValidateFirstName && !isFirstNameValid}
                        helperText={shouldValidateFirstName && firstNameError}
                        sx={{
                            mb: { md: 0, sm: 1, xs: 4 },
                        }}
                        inputRef={firstNameRef}
                        onChange={(e): void => {
                            // eslint-disable-next-line no-unused-expressions
                            firstNameTextFieldProps?.onChange && firstNameTextFieldProps.onChange(e);
                            handleFirstNameInputChange(e.target.value);
                        }}
                        onKeyUp={(e): void => {
                            if (e.key === 'Enter' && lastNameRef.current) lastNameRef.current.focus();
                        }}
                        onBlur={(): void => setShouldValidateFirstName(true)}
                    />
                    <TextField
                        id="last"
                        fullWidth
                        variant="filled"
                        sx={{
                            mt: { md: 4, sm: 3 },
                        }}
                        inputRef={lastNameRef}
                        label={lastNameLabel}
                        required={true}
                        value={lastNameInput}
                        error={shouldValidateLastName && !isLastNameValid}
                        helperText={shouldValidateLastName && lastNameError}
                        onChange={(e): void => {
                            // eslint-disable-next-line no-unused-expressions
                            lastNameTextFieldProps?.onChange && lastNameTextFieldProps.onChange(e);
                            handleLastNameInputChange(e.target.value);
                        }}
                        onKeyUp={(e): void => {
                            if (e.key === 'Enter' && isFirstNameValid && isLastNameValid && actionsProps.canGoNext)
                                actionsProps.onNext?.();
                        }}
                        onBlur={(): void => setShouldValidateLastName(true)}
                    />
                    <TextField
                        id="companyName"
                        fullWidth
                        variant="filled"
                        sx={{
                            mt: { md: 4, sm: 3 },
                        }}
                        inputRef={companyNameRef}
                        label={companyNameLabel}
                        value={companyNameInput}
                        error={shouldValidateCompanyName && !isCompanyNameValid}
                        helperText={shouldValidateCompanyName && companyNameError}
                        onChange={(e): void => {
                            // eslint-disable-next-line no-unused-expressions
                            companyNameTextFieldProps?.onChange && companyNameTextFieldProps.onChange(e);
                            handleCompanyNameInputChange(e.target.value);
                        }}
                        onKeyUp={(e): void => {
                            if (e.key === 'Enter' && isCompanyNameValid && actionsProps.canGoNext)
                                actionsProps.onNext?.();
                        }}
                        onBlur={(): void => {
                            // Only validate the input if there is text present
                            setShouldValidateCompanyName(companyNameInput.length > 0);
                        }}
                    />
                    <TextField
                        id="phoneNumber"
                        fullWidth
                        variant="filled"
                        sx={{
                            mt: { md: 4, sm: 3 },
                        }}
                        inputRef={phoneNumberRef}
                        label={phoneNumberLabel}
                        value={phoneNumberInput}
                        error={shouldValidatePhoneNumber && !isPhoneNumberValid}
                        helperText={shouldValidatePhoneNumber && phoneNumberError}
                        onChange={(e): void => {
                            // eslint-disable-next-line no-unused-expressions
                            phoneNumberTextFieldProps?.onChange && phoneNumberTextFieldProps.onChange(e);
                            handlePhoneNumberInputChange(e.target.value);
                        }}
                        onKeyUp={(e): void => {
                            if (e.key === 'Enter' && isPhoneNumberValid && actionsProps.canGoNext)
                                actionsProps.onNext?.();
                        }}
                        onBlur={(): void => {
                            // Only validate the input if there is text present
                            setShouldValidatePhoneNumber(phoneNumberInput.length > 0);
                        }}
                    />
                </ErrorManager>
            </WorkflowCardBody>
            <WorkflowCardActions
                {...actionsProps}
                canGoNext={
                    actionsProps.canGoNext &&
                    isFirstNameValid &&
                    isLastNameValid &&
                    isCompanyNameValid &&
                    isPhoneNumberValid
                }
            />
        </WorkflowCard>
    );
};
