import {
    SuccessScreenBase,
    useRegistrationContext,
    useRegistrationWorkflowContext,
} from '@brightlayer-ui/react-auth-workflow';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RegistrationSuccessScreenProps as RegistrationSuccessPageProps } from './types';

/**
 * Component that renders a success screen for when registration completes.
 *
 * @param {RegistrationSuccessPageProps} props - props of SuccessScreen
 *
 * @category Component
 */

export const RegistrationSuccessPage: React.FC<RegistrationSuccessPageProps> = (props) => {
    const { navigate, routeConfig } = useRegistrationContext();
    const { t } = useTranslation();

    const {
        screenData: {
            AccountDetails: { firstName, lastName },
            CreateAccount: { emailAddress: email },
            Other: {
                // @ts-ignore
                RegistrationSuccessScreen: { organizationName: organization },
            },
        },
    } = useRegistrationWorkflowContext();

    const {
        onDismiss = (): void => navigate(routeConfig.LOGIN as string),
        canDismiss = true,
        WorkflowCardHeaderProps,
        WorkflowCardActionsProps,
        EmptyStateProps,
        ...otherRegistrationSuccessScreenProps
    } = props;

    const workflowCardHeaderProps = {
        title: t('bluiRegistration:REGISTRATION.STEPS.COMPLETE'),
        ...WorkflowCardHeaderProps,
    };

    const workflowCardActionsProps = {
        nextLabel: t('bluiCommon:ACTIONS.FINISH'),
        showNext: true,
        canGoNext: canDismiss,
        fullWidthButton: true,
        ...WorkflowCardActionsProps,
        onNext: (): void => {
            onDismiss();
            WorkflowCardActionsProps?.onNext?.();
        },
    };

    const emptyStateProps = {
        icon: <CheckCircle color={'primary'} sx={{ fontSize: 100, mb: 2 }} />,
        title: `${t('bluiCommon:MESSAGES.WELCOME')}, ${firstName} ${lastName}!`,
        description: (
            <Box>
                <Typography variant="subtitle2" sx={{ marginTop: '10px' }}>
                    <Trans i18nKey={'REGISTRATION_SUCCESS_PAGE.SUCCESS_MESSAGE'} values={{ email }}>
                        Your account has successfully been created with the email <b>{email}</b>.
                    </Trans>
                </Typography>
                {props.hasEatonAccount && (
                    <Typography variant="subtitle2" sx={{ marginTop: '10px' }}>
                        <Trans
                            i18nKey={'REGISTRATION_SUCCESS_PAGE.HAS_EATON_ACCOUNT_MESSAGE'}
                            values={{ email, organization }}
                        />
                    </Typography>
                )}
            </Box>
        ),
        ...EmptyStateProps,
    };

    return (
        <SuccessScreenBase
            WorkflowCardHeaderProps={workflowCardHeaderProps}
            WorkflowCardActionsProps={workflowCardActionsProps}
            EmptyStateProps={emptyStateProps}
            {...otherRegistrationSuccessScreenProps}
        />
    );
};
