// import * as Colors from '@brightlayer-ui/colors';
import { useRegistrationContext, useRegistrationWorkflowContext } from '@brightlayer-ui/react-auth-workflow';
import { ErrorManagerProps } from '@brightlayer-ui/react-auth-workflow/dist/components/Error/types';
import { useErrorManager } from '@brightlayer-ui/react-auth-workflow/dist/contexts/ErrorContext/useErrorManager';
import { AxiosError, HttpStatusCode } from 'axios';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserService } from '../../../services/user/user.service';
import { CreateAccountPageBase } from './CreateAccountPageBase';
import { CreateAccountPageProps } from './types';
/**
 * Component that renders a screen for the user to enter their email address and invite code
 * to start the account creation process.
 *
 * This component has been ported over from BLUI React Workflows and modified.
 *
 * @param {CreateAccountPageProps} props - props of CreateAccountScreen
 *
 * @category Component
 */

const EMAIL_REGEX = /^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const INVITE_CODE_REGEX = /^[A-Z0-9]{6}$/i;

export const CreateAccountPage: React.FC<CreateAccountPageProps> = (props) => {
    const { t } = useTranslation();
    const { actions } = useRegistrationContext();
    const regWorkflow = useRegistrationWorkflowContext();
    const { nextScreen, previousScreen, screenData, totalScreens, currentScreen } = regWorkflow;

    const {
        WorkflowCardBaseProps,
        WorkflowCardHeaderProps,
        WorkflowCardInstructionProps,
        WorkflowCardActionsProps,
        emailLabel = t('bluiCommon:LABELS.EMAIL'),
        emailInitialValue = screenData.CreateAccount.emailAddress,
        emailValidator = (email: string): boolean | string => {
            if (!EMAIL_REGEX.test(email)) {
                return t('bluiCommon:MESSAGES.EMAIL_ENTRY_ERROR');
            }
            return true;
        },
        emailTextFieldProps,
        inviteCodeLabel = t('CREATE_ACCOUNT_PAGE.INVITE_CODE_LABEL'),
        inviteCodeValidator = (inviteCode: string): boolean | string => {
            if (!INVITE_CODE_REGEX.test(inviteCode)) {
                return t('CREATE_ACCOUNT_PAGE.INVITE_CODE_ERROR');
            }
            return true;
        },
        inviteCodeScreenData,
        setInviteCodeScreenData,
        setHasEatonAccount,
        ...otherProps
    } = props;

    const [emailInputValue, setEmailInputValue] = useState(screenData.CreateAccount.emailAddress);
    const [inviteCodeInputValue, setInviteCodeInputValue] = useState(inviteCodeScreenData ?? '');
    const [isLoading, setIsLoading] = useState(false);
    const { triggerError, errorManagerConfig } = useErrorManager();

    const errorDisplayConfig: ErrorManagerProps = {
        ...errorManagerConfig,
        ...props.errorDisplayConfig,
        onClose: (): void => {
            if (props.errorDisplayConfig && props.errorDisplayConfig.onClose) props.errorDisplayConfig.onClose();
            if (errorManagerConfig.onClose) errorManagerConfig?.onClose();
        },
    };

    const onNext = useCallback(async () => {
        try {
            setIsLoading(true);
            const { hasEatonAccount } = await UserService.validateRegistration({
                email: emailInputValue,
                inviteCode: inviteCodeInputValue,
            });

            if (setHasEatonAccount) {
                setHasEatonAccount(hasEatonAccount);
            }

            if (setInviteCodeScreenData) {
                setInviteCodeScreenData(inviteCodeInputValue);
            }

            void nextScreen({
                screenId: 'CreateAccount',
                values: { emailAddress: emailInputValue },
            });
        } catch (error) {
            if (error instanceof AxiosError && error.response?.status === HttpStatusCode.Conflict) {
                triggerError(new Error(t('CREATE_ACCOUNT_PAGE.EMAIL_ALREADY_EXISTS_ERROR')));
            } else if (error instanceof AxiosError && error.response?.status === HttpStatusCode.BadRequest) {
                triggerError(new Error(t('CREATE_ACCOUNT_PAGE.INVITE_CODE_ERROR')));
            } else {
                triggerError(error as Error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [actions, emailInputValue, nextScreen, triggerError]);

    const onPrevious = (): void => {
        previousScreen({
            screenId: 'CreateAccount',
            values: { emailAddress: emailInputValue },
        });
    };

    const workflowCardBaseProps = {
        loading: isLoading,
        ...WorkflowCardBaseProps,
    };

    const workflowCardHeaderProps = {
        title: t('bluiRegistration:REGISTRATION.STEPS.CREATE_ACCOUNT'),
        ...WorkflowCardHeaderProps,
    };

    const workflowCardInstructionProps = {
        instructions: t('bluiRegistration:SELF_REGISTRATION.INSTRUCTIONS'),
        ...WorkflowCardInstructionProps,
    };

    const workflowCardActionsProps = {
        showNext: true,
        nextLabel: t('bluiCommon:ACTIONS.NEXT'),
        showPrevious: true,
        previousLabel: t('bluiCommon:ACTIONS.BACK'),
        canGoPrevious: true,
        currentStep: currentScreen,
        totalSteps: totalScreens,
        ...WorkflowCardActionsProps,
        onNext: (): void => {
            void onNext();
            WorkflowCardActionsProps?.onNext?.();
        },
        onPrevious: (): void => {
            void onPrevious();
            WorkflowCardActionsProps?.onPrevious?.();
        },
    };

    const onEmailInputValueChange = (e: any): void => {
        setEmailInputValue(e.target.value);
    };

    const onInviteCodeInputValueChange = (e: any): void => {
        setInviteCodeInputValue(e.target.value);
    };

    return (
        <CreateAccountPageBase
            WorkflowCardBaseProps={workflowCardBaseProps}
            WorkflowCardHeaderProps={workflowCardHeaderProps}
            WorkflowCardInstructionProps={workflowCardInstructionProps}
            emailLabel={emailLabel}
            emailInitialValue={
                screenData.CreateAccount.emailAddress.length > 0
                    ? screenData.CreateAccount.emailAddress
                    : emailInitialValue
            }
            emailTextFieldProps={{ ...emailTextFieldProps, onChange: onEmailInputValueChange }}
            emailValidator={emailValidator}
            inviteCodeLabel={inviteCodeLabel}
            inviteCodeValidator={inviteCodeValidator}
            inviteCodeTextFieldProps={{ onChange: onInviteCodeInputValueChange }}
            WorkflowCardActionsProps={workflowCardActionsProps}
            errorDisplayConfig={errorDisplayConfig}
            {...otherProps}
        />
    );
};
