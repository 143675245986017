import * as Colors from '@brightlayer-ui/colors';
import { AccountCircle, Code, Domain } from '@mui/icons-material';
import { Box, Button, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../app/hooks';
import { IconCard } from '../components/IconCard';
import { PageWrapper } from '../components/PageWrapper';
import { ConfigClient } from '../config';
import { UserSelectors } from '../features/userSlice';

const useStyles = (theme: Theme): typeof styles => {
    const styles = {
        loginButton: {
            color: Colors.white['50'],
            backgroundColor: 'transparent',
            fontSize: '14px',
        },
        pageBackground: {
            backgroundColor: theme.palette.background.paper,
            minHeight: '100vh',
            position: 'relative',
        },
        container: {
            paddingX: '1rem',
            display: 'flex',
            flexDirection: 'column',
        },
        header: {
            textAlign: 'center',
        },
        body: {
            minHeight: `calc(100vh - ${theme.spacing(8)})`,
            backgroundImage:
                'linear-gradient(90deg, rgba(229, 229, 229, 0.7), rgba(255, 255, 255, 1.0) 50%), url(/images/title-overlay.png)',
            [theme.breakpoints.down('xs')]: {
                minHeight: `calc(100vh - ${theme.spacing(7)})`,
            },
        },
        title: {
            color: Colors.blue[500],
            paddingTop: '25px',
            paddingRight: '190px',
            paddingLeft: '190px',
        },
        subtitle: {
            marginTop: '26px',
        },
        button: {
            maxWidth: '100px',
            margin: '16px',
            marginTop: '26px',
        },
        icon: {
            mr: 3,
        },
        contentBlock: {
            display: 'flex',
            gap: '60px',
            alignItems: 'center',
        },
        textContent: {
            display: 'flex',
            flexDirection: 'column',
            width: '60%',
        },
        imageContent: {
            display: 'flex',
            flexDirection: 'column',
            width: '40%',
        },
        headline: {
            color: Colors.blue[500],
            marginBottom: '15px',
        },
        headerContent: {
            maxWidth: '90%',
        },
        learnMoreButton: {
            marginTop: '1rem',
        },
        gettingStartedContainer: {
            paddingTop: '32px',
            paddingBottom: '32px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
            gap: '16px',
            justifyContent: 'center',
        },
        largeButton: {
            padding: '16px',
        },
    };
    return styles;
};

export const HomePage = (): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const styles = useStyles(theme);
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const credentials = useAppSelector(UserSelectors.credentials);

    return (
        <Box sx={styles.pageBackground}>
            <PageWrapper title={t('COMMON.HOME')}>
                <Box sx={styles.body}>
                    <Box sx={styles.header}>
                        <Typography sx={styles.title} variant={sm ? 'h4' : 'h2'} paragraph data-testid={'home-title'}>
                            {t('HOME_PAGE.TITLE')}
                        </Typography>
                        <Typography sx={styles.subtitle} variant={'body1'} data-testid={'home-subtitle'}>
                            {t('HOME_PAGE.SUBTITLE')}
                        </Typography>
                    </Box>
                    <Box sx={styles.container}>
                        <Box sx={styles.gettingStartedContainer}>
                            <Typography variant="h4" sx={styles.headline} data-testid={'getting-started-title'}>
                                {t('HOME_PAGE.GETTING_STARTED_TITLE')}
                            </Typography>
                            <Box sx={styles.buttonContainer}>
                                {!credentials && (
                                    <IconCard
                                        title={t('HOME_PAGE.CARD_LOGIN_OR_CREATE_ACCOUNT_TITLE')}
                                        body={t('HOME_PAGE.CARD_LOGIN_OR_CREATE_ACCOUNT_BODY')}
                                        icon={<AccountCircle fontSize="inherit" />}
                                        onClick={(): void => navigate('/login')}
                                    />
                                )}
                                {credentials && (
                                    <IconCard
                                        title={t('HOME_PAGE.CARD_GO_TO_ORGANIZATIONS_TITLE')}
                                        body={t('HOME_PAGE.CARD_GO_TO_ORGANIZATIONS_BODY')}
                                        icon={<Domain fontSize="inherit" />}
                                        onClick={(): void => navigate('/organizations')}
                                    />
                                )}
                                <IconCard
                                    title={t('HOME_PAGE.CARD_VIEW_API_DOCUMENTATION_TITLE')}
                                    body={t('HOME_PAGE.CARD_VIEW_API_DOCUMENTATION_BODY')}
                                    icon={<Code fontSize="inherit" />}
                                    onClick={(): void => {
                                        const url = ConfigClient.apiDocumentationUrl(window);
                                        if (url) {
                                            window.open(url);
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box sx={styles.contentBlock}>
                            <Box sx={styles.imageContent}>
                                <img src="/images/breaker.png" alt="" />
                            </Box>
                            <Box sx={styles.textContent}>
                                <Box sx={(styles.headerContent, { alignSelf: 'start' })}>
                                    <Typography variant="h4" sx={styles.headline}>
                                        {t('HOME_PAGE.SMART_BREAKERS_TITLE')}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>{t('HOME_PAGE.SMART_BREAKERS_SUBTITLE')}</strong>
                                    </Typography>
                                    <Typography variant="body1">{t('HOME_PAGE.SMART_BREAKERS_CONTENT')}</Typography>
                                    <Button
                                        variant="contained"
                                        sx={styles.learnMoreButton}
                                        onClick={(): void => {
                                            window.open(
                                                'https://www.eaton.com/content/dam/eaton/products/residential/ableedge-home-energy-management-system/eaton-ableedge-smart-breaker-spec-sheet-TD003020en.pdf'
                                            );
                                        }}
                                    >
                                        {t('HOME_PAGE.LEARN_MORE')}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </PageWrapper>
        </Box>
    );
};
