import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { getComponentsForPage } from '../markdown-content/component-mapping';
import { DisplayError } from './DisplayError';
import { ScrollToTopButton } from './ScrollToTopButton';

const styles = {
    errorDisplay: {
        margin: '40px 0px',
    },
};

type MarkdownProps = {
    source: string;
    pageRoute: string;
};

export const MarkdownContent = (props: MarkdownProps): JSX.Element => {
    const [postMarkdown, setPostMarkdown] = useState('');

    useEffect(() => {
        try {
            if (!new RegExp(/^.*.(md)$/).test(props.source)) {
                throw new Error();
            }
            void fetch(props.source)
                .then((response) => response.text())
                .then((text) => {
                    setPostMarkdown(text);
                });
        } catch (e) {
            setPostMarkdown('error');
        }
    }, [props.source]);

    return postMarkdown === 'error' ? (
        <Box sx={styles.errorDisplay}>
            <DisplayError errorMessage={`Failed to load markdown for ${props.source}`} />
        </Box>
    ) : (
        <div>
            <ReactMarkdown components={getComponentsForPage(props.pageRoute)} remarkPlugins={[remarkGfm]}>
                {postMarkdown}
            </ReactMarkdown>
            <ScrollToTopButton />
        </div>
    );
};
