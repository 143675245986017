import { buildEnergyManagerClient, buildEnergyManagerClientWithoutAuth } from '../apiClient';
import {
    ChangePassword,
    CreateUser,
    GetUserRolesResponse,
    IUserService,
    LoginResponse,
    LoginToken,
    UpdateUser,
    User,
    ValidateRegistrationRequest,
    ValidateRegistrationResponse,
} from './types';

const login = async (email: string, password: string): Promise<LoginResponse> => {
    const apiClient = buildEnergyManagerClientWithoutAuth();

    const response = await apiClient.post<LoginResponse>('/api/users/login', { email, password });
    return response.data;
};

const logout = async (userId: string): Promise<void> => {
    const apiClient = buildEnergyManagerClient();

    await apiClient.post(`/api/users/${userId}/logout`);
};

const getUser = async (userId: string, token: string): Promise<User> => {
    const apiClient = buildEnergyManagerClientWithoutAuth({ headers: { authorization: `Bearer ${token}` } });

    const response = await apiClient.get<User>(`/api/users/${userId}`);
    return response.data;
};

const registerUser = async (args: CreateUser): Promise<User> => {
    const apiClient = buildEnergyManagerClientWithoutAuth();

    const response = await apiClient.post<User>('/api/users', args);
    return response.data;
};

const refreshAccessToken = async (accessToken: string, refreshToken: string): Promise<LoginToken> => {
    const apiClient = buildEnergyManagerClientWithoutAuth();

    const response = await apiClient.post('/api/users/refresh-login', { accessToken, refreshToken });

    return response.data;
};

const getUserRoles = async (userId: string): Promise<GetUserRolesResponse[]> => {
    const apiClient = buildEnergyManagerClient();

    const response = await apiClient.get<GetUserRolesResponse[]>(`/api/users/${userId}/roles`);
    return response.data;
};

const validateRegistration = async (args: ValidateRegistrationRequest): Promise<ValidateRegistrationResponse> => {
    const apiClient = buildEnergyManagerClientWithoutAuth();

    const response = await apiClient.post<ValidateRegistrationResponse>('/api/users/validate-registration', args);

    return response.data;
};

const updateUser = async (args: UpdateUser): Promise<User> => {
    const apiClient = buildEnergyManagerClient();

    const body = {
        firstName: args.firstName,
        lastName: args.lastName,
        phoneNumber: args.phoneNumber,
        company: args.company,
    };

    const response = await apiClient.put<User>(`/api/users/${args.id}`, body);

    return response.data;
};

const changePassword = async (args: ChangePassword): Promise<void> => {
    const apiClient = buildEnergyManagerClient();

    const body = {
        currentPassword: args.currentPassword,
        newPassword: args.newPassword,
    };

    await apiClient.put(`/api/users/${args.id}/password`, body);
};

const forgotPassword = async (email: string): Promise<void> => {
    const apiClient = buildEnergyManagerClient();

    const body = {
        email,
    };

    await apiClient.post('/api/users/password-reset', body);
};

export const UserService: IUserService = {
    login,
    logout,
    getUser,
    registerUser,
    refreshAccessToken,
    getUserRoles,
    validateRegistration,
    updateUser,
    changePassword,
    forgotPassword,
};
