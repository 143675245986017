import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MarkdownContent } from '../../components/MarkdownContent';
import { PageWrapper } from '../../components/PageWrapper';
import source from '../../markdown-content/advancedTopics.english.md';

const styles = {
    contentBlock: {
        padding: '30px 45px 30px 30px',
    },
};

export const AdvancedTopics: React.FC = () => {
    const { t } = useTranslation();

    return (
        <PageWrapper title={t('ADVANCED_TOPICS.TITLE')}>
            <Box sx={styles.contentBlock}>
                <MarkdownContent source={source} pageRoute="advancedTopics" />
            </Box>
        </PageWrapper>
    );
};
