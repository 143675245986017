import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from '../../components/PageWrapper';
import { ScrollableNav } from '../../components/ScrollableNav';
import source from '../../markdown-content/udpKeys.english.md';

const UDP_KEYS_SECTION = (t: TFunction): Array<{ id: string; title: string }> => [
    {
        id: 'udpKeys',
        title: t('ADVANCED_TOPICS.UDP_KEYS.UDP_KEYS_TITLE'),
    },
    {
        id: 'keyTypes',
        title: t('ADVANCED_TOPICS.UDP_KEYS.KEY_TYPES_TITLE'),
    },
    {
        id: 'generateUdpKey',
        title: t('ADVANCED_TOPICS.UDP_KEYS.GENERATE_UDP_KEY_TITLE'),
    },
    {
        id: 'assignUdpKey',
        title: t('ADVANCED_TOPICS.UDP_KEYS.ASSIGN_UDP_KEY_TITLE'),
    },
];

export const UdpKeysPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <PageWrapper title={t('ADVANCED_TOPICS.UDP_KEYS.UDP_KEYS_TITLE')}>
            <ScrollableNav sectionList={UDP_KEYS_SECTION(t)} source={source} pageRoute="/advancedTopics/udpKeys" />
        </PageWrapper>
    );
};
