import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';

import * as Colors from '@brightlayer-ui/colors';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { FilledTextField } from '../../components/FilledTextField';
import { GenericTextAndTitle } from '../../components/GenericTextAndTitle';
import { useAlert } from '../../lib/AlertContext';
import { OrganizationService } from '../../services/organization/organization.service';
import { CreatedOrganization } from '../../services/organization/types';
import { OrganizationFormValidations } from '../validations';

const styles = {
    sectionHeader: {
        paddingBottom: 2,
    },
    title: {
        color: Colors.blue['500'],
    },
    content: {
        color: Colors.black['500'],
    },
    textAndTitle: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
    },
    organizationNameForm: {
        display: 'flex',
        flexDirection: 'column',
    },
    textField: {
        maxWidth: '400px',
        color: Colors.white[200],
        width: '100%',
    },
    formHelperText: {
        marginLeft: '0px',
    },
    submitButton: {
        maxWidth: '90px',
        marginTop: '20px',
        textTransform: 'uppercase',
    },
    circularProgress: {
        color: 'white',
    },
    container: {
        paddingLeft: '25px',
        paddingTop: '25px',
    },
};

type CreateApplicationFormProps = {
    activationKey: string;
    setCreatedOrganization: (organization: CreatedOrganization) => void;
};

export const CreateOrganizationForm: React.FC<CreateApplicationFormProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showErrorToUser } = useAlert();

    const [organizationName, setOrganizationName] = useState<string>();
    const [organizationDescription, setOrganizationDescription] = useState<string>();
    const [pointOfContactName, setPointOfContactName] = useState<string>();
    const [pointOfContactEmail, setPointOfContactEmail] = useState<string>();
    const [pointOfContactPhoneNumber, setPointOfContactPhoneNumber] = useState<string>();

    const [organizationNameError, setOrganizationNameError] = useState(false);
    const [organizationDescriptionError, setOrganizationDescriptionError] = useState(false);
    const [pointOfContactNameError, setPointOfContactNameError] = useState(false);
    const [pointOfContactEmailError, setPointOfContactEmailError] = useState(false);
    const [pointOfContactPhoneNumberError, setPointOfContactPhoneNumberError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const submitDisabled =
        !organizationDescription ||
        !pointOfContactName ||
        !pointOfContactEmail ||
        !organizationName ||
        isLoading ||
        organizationNameError ||
        organizationDescriptionError ||
        pointOfContactNameError ||
        pointOfContactEmailError ||
        pointOfContactPhoneNumberError;

    const renderFailedToCreateError = (): void => {
        showErrorToUser({
            errorTitle: t('CREATE_ORGANIZATION_PAGE.FAILED_TO_CREATE_ORG_TITLE'),
            errorDescription: t('CREATE_ORGANIZATION_PAGE.FAILED_TO_CREATE_ORG_BODY'),
            onCancel: () => {
                navigate('/organizations');
            },
            confirmText: t('COMMON.TRY_AGAIN'),
            cancelText: t('COMMON.CANCEL'),
            onConfirm: () => {},
        });
    };

    const handleOnClick = async (): Promise<void> => {
        if (
            organizationName &&
            organizationDescription &&
            pointOfContactName &&
            pointOfContactEmail &&
            props.activationKey
        ) {
            setIsLoading(true);

            try {
                const createdOrganization = await OrganizationService.createOrganization({
                    activationKey: props.activationKey,
                    organization: {
                        name: organizationName,
                        description: organizationDescription,
                        pointOfContact: {
                            name: pointOfContactName,
                            email: pointOfContactEmail,
                            phoneNumber: pointOfContactPhoneNumber,
                        },
                    },
                });

                props.setCreatedOrganization(createdOrganization);
            } catch (error) {
                renderFailedToCreateError();
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.organizationNameForm}>
                <Box sx={styles.sectionHeader}>
                    <Typography variant="h6">{t('CREATE_ORGANIZATION_PAGE.ORG_DETAILS')}</Typography>
                    <Typography variant="caption">{t('CREATE_ORGANIZATION_PAGE.ORG_DETAILS_INSTRUCTIONS')}</Typography>
                </Box>
                <Box>
                    <FilledTextField
                        label={t('CREATE_ORGANIZATION_PAGE.ORG_NAME_LABEL')}
                        data-testid="organization-name-text-box"
                        sx={styles.textField}
                        FormHelperTextProps={{ sx: styles.formHelperText }}
                        error={organizationNameError}
                        helperText={organizationNameError ? t('CREATE_ORGANIZATION_PAGE.ORG_NAME_ERROR') : undefined}
                        required={true}
                        onChange={(event): void => {
                            setOrganizationNameError(false);
                            setOrganizationName(event.target.value);
                        }}
                        onBlur={(): void => {
                            if (organizationName !== undefined) {
                                setOrganizationNameError(
                                    !OrganizationFormValidations.organizationNameValidator(organizationName)
                                );
                            }
                        }}
                    />

                    <FilledTextField
                        label={t('CREATE_ORGANIZATION_PAGE.ORG_DESC_LABEL')}
                        data-testid="organization-description-text-box"
                        sx={styles.textField}
                        FormHelperTextProps={{ sx: styles.formHelperText }}
                        error={organizationDescriptionError}
                        helperText={
                            organizationDescriptionError
                                ? t('CREATE_ORGANIZATION_PAGE.ORG_DESCRIPTION_ERROR')
                                : undefined
                        }
                        required={true}
                        onChange={(event): void => {
                            setOrganizationDescriptionError(false);
                            setOrganizationDescription(event.target.value);
                        }}
                        onBlur={(): void => {
                            if (organizationDescription !== undefined) {
                                setOrganizationDescriptionError(
                                    !OrganizationFormValidations.organizationDescriptionValidator(
                                        organizationDescription
                                    )
                                );
                            }
                        }}
                    />
                </Box>
                <Box sx={styles.sectionHeader}>
                    <Typography variant="h6">{t('CREATE_ORGANIZATION_PAGE.POC_DETAILS')}</Typography>
                    <Typography variant="caption">{t('CREATE_ORGANIZATION_PAGE.POC_INSTRUCTIONS')}</Typography>
                </Box>
                <Box>
                    <FilledTextField
                        label={t('CREATE_ORGANIZATION_PAGE.POC_NAME_LABEL')}
                        data-testid="point-of-contact-name-text-box"
                        sx={styles.textField}
                        FormHelperTextProps={{ sx: styles.formHelperText }}
                        error={pointOfContactNameError}
                        helperText={pointOfContactNameError ? t('CREATE_ORGANIZATION_PAGE.POC_NAME_ERROR') : undefined}
                        required={true}
                        onChange={(event): void => {
                            setPointOfContactNameError(false);
                            setPointOfContactName(event.target.value);
                        }}
                        onBlur={(): void => {
                            if (pointOfContactName !== undefined) {
                                setPointOfContactNameError(
                                    !OrganizationFormValidations.pointOfContactNameValidator(pointOfContactName)
                                );
                            }
                        }}
                    />
                    <FilledTextField
                        label={t('CREATE_ORGANIZATION_PAGE.POC_EMAIL_LABEL')}
                        data-testid="point-of-contact-email-text-box"
                        sx={styles.textField}
                        FormHelperTextProps={{ sx: styles.formHelperText }}
                        error={pointOfContactEmailError}
                        helperText={
                            pointOfContactEmailError ? t('CREATE_ORGANIZATION_PAGE.POC_EMAIL_ERROR') : undefined
                        }
                        required={true}
                        onChange={(event): void => {
                            setPointOfContactEmailError(false);
                            setPointOfContactEmail(event.target.value);
                        }}
                        onBlur={(): void => {
                            if (pointOfContactEmail !== undefined) {
                                setPointOfContactEmailError(
                                    !OrganizationFormValidations.pointOfContactEmailValidator(pointOfContactEmail)
                                );
                            }
                        }}
                    />
                    <FilledTextField
                        label={t('CREATE_ORGANIZATION_PAGE.POC_PHONE_LABEL')}
                        data-testid="point-of-contact-phone-number-text-box"
                        sx={styles.textField}
                        FormHelperTextProps={{ sx: styles.formHelperText }}
                        error={pointOfContactPhoneNumberError}
                        helperText={
                            pointOfContactPhoneNumberError
                                ? t('CREATE_ORGANIZATION_PAGE.POC_PHONE_NUMBER_ERROR')
                                : undefined
                        }
                        onChange={(event): void => {
                            setPointOfContactPhoneNumberError(false);
                            setPointOfContactPhoneNumber(event.target.value);
                        }}
                        onBlur={(): void => {
                            if (pointOfContactPhoneNumber) {
                                setPointOfContactPhoneNumberError(
                                    !OrganizationFormValidations.pointOfContactPhoneNumberValidator(
                                        pointOfContactPhoneNumber
                                    )
                                );
                            }
                        }}
                    />
                </Box>
                <Box>
                    <Typography variant="h6"> {t('CREATE_ORGANIZATION_PAGE.SUBSCRIPTION_DETAILS')}</Typography>
                    <GenericTextAndTitle
                        id="activation-txt-id"
                        title={t('CREATE_ORGANIZATION_PAGE.ACTIVATION_KEY')}
                        content={props.activationKey}
                    />
                </Box>
                <Button
                    sx={styles.submitButton}
                    id="submit-button"
                    variant="contained"
                    color="primary"
                    onClick={(): void => void handleOnClick()}
                    disabled={submitDisabled}
                >
                    {isLoading ? (
                        <CircularProgress sx={styles.circularProgress} size={20} variant={'indeterminate'} />
                    ) : (
                        t('COMMON.SUBMIT')
                    )}
                </Button>
            </Box>
        </Box>
    );
};
