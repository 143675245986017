import * as Colors from '@brightlayer-ui/colors';
import {
    Box,
    Link as MuiLink,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import { ContentAnchor } from '../components/ContentAnchor';

export const styles = {
    image: {
        display: 'flex',
        maxWidth: '100%',
        marginTop: '8px',
        marginBottom: '8px',
    },
    codeBlock: {
        backgroundColor: Colors.gray[500],
        margin: '30px',
        padding: '10px',
        color: Colors.white[50],
    },
    table: {
        border: Colors.gray[500],
    },
    blockquote: {
        padding: '0 1em',
        color: '#6a737d',
        borderLeft: '0.25em solid #dfe2e5',
    },
    paragraph: {
        marginTop: '8px',
    },
    anchor: {
        position: 'relative',
        top: '-90px',
        left: '0',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '24px',
    },
    contentBlock: (theme: Theme): object => ({
        marginTop: '20px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '50%',
        [theme.breakpoints.down('sm')]: { maxWidth: '90%' },
    }),
    subheader: {
        marginTop: '16px',
    },
    link: {
        margin: '4px',
    },
    divider: {
        width: '100%',
        margin: '24px 0px',
        color: '#0000001f',
        borderStyle: 'solid',
        borderBottomStyle: 'none',
        height: '1px',
    },
    code: {
        fontSize: '0.813rem',
        padding: '0 5px',
        inlineSize: '80%',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
    },
    href: {
        textDecoration: 'none',
        color: '#32329f',
    },
    tableRow: (theme: Theme): object => ({
        '&:nth-of-type(odd)': {
            backgroundColor: 'white',
        },
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
        },
    }),
};

const MakeTypographyComponent = (
    props: any,
    header: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
    pageRoute?: string
): JSX.Element => (
    <Typography
        variant={header}
        data-testid={`${_.kebabCase(_.lowerCase(props.children))}-header`}
        {...props}
        sx={header !== 'h1' && styles.subheader}
    >
        <ContentAnchor {...props} sx={styles.anchor} id={_.camelCase(props.children)} page={pageRoute} />
    </Typography>
);

const TypographyWithContentAnchor =
    (pageRoute: string, headerSize: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6') =>
    ({ ...props }): JSX.Element =>
        MakeTypographyComponent(props, headerSize, pageRoute);

const TypographyBody1 = ({ ...props }): JSX.Element => <Typography variant="body1" {...props} sx={styles.paragraph} />;

const Image = ({ ...props }): JSX.Element => <Box component="img" {...props} sx={styles.image} alt="" />;

const PreBlock = ({ ...props }): JSX.Element => <Box component="pre" {...props} sx={styles.codeBlock} />;

const MarkdownTable = ({ ...props }): JSX.Element => (
    <TableContainer component={Paper}>
        <Table size="small">{props.children}</Table>
    </TableContainer>
);

const MarkdownTableRow = ({ ...props }): JSX.Element => <TableRow sx={styles.tableRow}>{props.children}</TableRow>;

const Blockquote = ({ ...props }): JSX.Element => <Box component="blockquote" {...props} sx={styles.blockquote} />;

const Link = ({ ...props }): JSX.Element => <Link {...props} sx={styles.link} />;

const Divider = ({ ...props }): JSX.Element => <Box component="hr" {...props} sx={styles.divider} />;

const Code = ({ ...props }): JSX.Element => <Box component="code" {...props} sx={styles.code} />;

const AHref = ({ ...props }): JSX.Element => {
    if (props.href.includes('http') || props.href.includes('https')) {
        return (
            <MuiLink role="link" target="_blank" {...props} sx={styles.href}>
                {props.children}
            </MuiLink>
        );
    }

    return (
        <MuiLink role="link" {...props} sx={styles.href}>
            {props.children}
        </MuiLink>
    );
};

export const getComponentsForPage = (pageRoute: string): any => ({
    h1: TypographyWithContentAnchor(pageRoute, 'h1'),
    h2: TypographyWithContentAnchor(pageRoute, 'h2'),
    h3: TypographyWithContentAnchor(pageRoute, 'h3'),
    h4: TypographyWithContentAnchor(pageRoute, 'h4'),
    h5: TypographyWithContentAnchor(pageRoute, 'h5'),
    h6: TypographyWithContentAnchor(pageRoute, 'h6'),
    img: Image,
    pre: PreBlock,
    blockquote: Blockquote,
    p: TypographyBody1,
    hr: Divider,
    code: Code,
    a: AHref,
    table: MarkdownTable,
    thead: TableHead,
    tbody: TableBody,
    tr: MarkdownTableRow,
    th: TableCell,
    td: TableCell,
});
