/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Colors from '@brightlayer-ui/colors';
import { Box, Button, Theme, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../lib/AlertContext';
import { OrganizationService } from '../../services/organization/organization.service';
import { ExternalApplication } from '../../services/organization/types';

const styles = {
    button: {
        ':hover': {
            bgcolor: Colors.blue[300],
        },
    },
    ableEdgeContainer: (theme: Theme): object => ({
        width: '575px',
        [theme.breakpoints.down('sm')]: {
            width: '425px',
        },
    }),
    sectionHeader: {
        paddingBottom: 2,
    },
    textContainer: {
        marginBottom: '16px',
    },
};

type ManageInstallerAppRegistrationProps = {
    organizationName: string;
    organizationId: string;
    isUserOwner: boolean;
    externalApp: ExternalApplication | undefined;
};

export const ManageInstallerAppRegistration: React.FC<ManageInstallerAppRegistrationProps> = (props) => {
    const { externalApp } = props;
    const isExternalAppAvailable = !!externalApp;

    const [isRegistered, setIsRegistered] = useState(
        isExternalAppAvailable ? externalApp.organizations.includes(props.organizationId) : false
    );
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const { showConfirmationToUser, showInfoAlertToUser } = useAlert();
    const { t } = useTranslation();

    const handleOnClickRegister = async (): Promise<void> => {
        try {
            setUpdateInProgress(true);
            await OrganizationService.shareOrgCredentialsWithExternalApplication({
                organizationId: props.organizationId,
            });
            setIsRegistered(true);
        } catch (e) {
            showInfoAlertToUser({
                title: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_REGISTER_ORG_TITLE'),
                description: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_REGISTER_ORG_BODY', {
                    organizationName: props.organizationName,
                }),
            });
        } finally {
            setUpdateInProgress(false);
        }
    };

    const handleOnClickRevoke = async (): Promise<void> => {
        try {
            setUpdateInProgress(true);
            await OrganizationService.revokeOrgCredentialsFromExternalApplication({
                organizationId: props.organizationId,
            });
            setIsRegistered(false);
        } catch (e) {
            showInfoAlertToUser({
                title: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_REVOKE_ORG_TITLE'),
                description: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_REVOKE_ORG_BODY', {
                    organizationName: props.organizationName,
                }),
            });
        } finally {
            setUpdateInProgress(false);
        }
    };

    const renderConfirmRegisterModal = (): void => {
        showConfirmationToUser({
            title: t('ORGANIZATION_DETAILS_PAGE.CONFIRM_ORG_REGISTRATION_TITLE'),
            description: t('ORGANIZATION_DETAILS_PAGE.CONFIRM_ORG_REGISTRATION_BODY', {
                organizationName: props.organizationName,
            }),
            onConfirm: (): void => void handleOnClickRegister(),
            confirmText: t('COMMON.YES'),
            cancelText: t('COMMON.NO'),
        });
    };

    const renderConfirmRevokeModal = (): void => {
        showConfirmationToUser({
            title: t('ORGANIZATION_DETAILS_PAGE.CONFIRM_ORG_REVOKE_TITLE'),
            description: t('ORGANIZATION_DETAILS_PAGE.CONFIRM_ORG_REVOKE_BODY', {
                organizationName: props.organizationName,
            }),
            onConfirm: (): void => void handleOnClickRevoke(),
            confirmText: t('COMMON.YES'),
            cancelText: t('COMMON.NO'),
        });
    };

    return (
        <Box data-testid="register-org-container" sx={styles.ableEdgeContainer}>
            <Typography variant="h5" sx={styles.sectionHeader}>
                {t('ORGANIZATION_DETAILS_PAGE.REGISTER_ORG_HEADER')}
            </Typography>
            <>
                <Box sx={styles.textContainer}>
                    <Typography variant="subtitle2">
                        {isRegistered
                            ? t('ORGANIZATION_DETAILS_PAGE.ORG_ALREADY_REGISTERED', {
                                  organizationName: props.organizationName,
                              })
                            : t('ORGANIZATION_DETAILS_PAGE.REGISTER_ORG')}
                    </Typography>
                    <Typography variant="subtitle2">
                        {t('ORGANIZATION_DETAILS_PAGE.REGISTER_ORG_EXPLANATION')}
                    </Typography>
                </Box>
                {props.isUserOwner && (
                    <Button
                        data-testid={isRegistered ? 'unregister-org-button' : 'register-org-button'}
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={styles.button}
                        disabled={!isExternalAppAvailable || updateInProgress}
                        onClick={(): void => (isRegistered ? renderConfirmRevokeModal() : renderConfirmRegisterModal())}
                    >
                        {isRegistered ? t('COMMON.REVOKE') : t('COMMON.REGISTER')}
                    </Button>
                )}
            </>
        </Box>
    );
};
