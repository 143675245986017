import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ErrorState } from '../../components/ErrorState';
import { PageWrapper } from '../../components/PageWrapper';
import { OrganizationDetails } from './OrganizationDetails';

export const OrganizationDetailsPage: React.FC = () => {
    const params = useParams();
    const { t } = useTranslation();

    const id = params.id ?? '';

    const [showError, setShowError] = useState(false);

    return (
        <PageWrapper title={t('ORGANIZATION_DETAILS_PAGE.TITLE')}>
            {showError ? <ErrorState /> : <OrganizationDetails orgId={id} setShowError={setShowError} />}
        </PageWrapper>
    );
};
