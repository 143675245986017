import { Box, CircularProgress, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';

const Styles = {
    background: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: 1000,
        backgroundColor: (theme: Theme): string =>
            theme.palette.mode === 'light' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.7)',
    },
};

type SpinnerProps = {
    visible?: boolean;
    sx?: SxProps<Theme>;
    size?: number;
    testId?: string;
};
/**
 * Component that renders a indeterminate circular progress spinner atop a semi-transparent white background.
 *
 * @param visible True if the spinner should be displayed, false to render nothing
 *
 * @category Component
 */
export const Spinner: React.FC<SpinnerProps> = (props) => {
    const visible = props.visible ?? true;

    return visible ? (
        <Box sx={props.sx || Styles.background} data-testid={props.testId ?? 'spinner-box'}>
            <CircularProgress size={props.size || 70} variant={'indeterminate'} />
        </Box>
    ) : (
        <></>
    );
};
