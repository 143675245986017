import { Box, Button } from '@mui/material';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: '1.563rem',
        marginRight: '1.563rem',
        marginTop: '25px',
    },
    createButton: {
        textTransform: 'uppercase',
    },
};

type NavBarButton = {
    buttonId?: string;
    buttonText: string;
    onClick: VoidFunction;
    startIcon?: React.ReactNode;
};

export const NavBarButton: React.FC<NavBarButton> = (props) => (
    <Box sx={styles.container}>
        <Button
            sx={styles.createButton}
            id={`${props.buttonId || 'nav-bar'}-button`}
            variant="contained"
            color="primary"
            startIcon={props.startIcon}
            onClick={props.onClick}
        >
            {props.buttonText}
        </Button>
    </Box>
);
