import { createContext, useContext } from 'react';

export type AppContextType = {
    showChangePasswordDialog: boolean;
    setShowChangePasswordDialog: (show: boolean) => void;
};

export const AppContext = createContext<AppContextType | null>(null);

export const useApp = (): AppContextType => {
    const context = useContext(AppContext);

    if (context === null) {
        throw new Error('useApp must be used within a AppContextProvider');
    }
    return context;
};
