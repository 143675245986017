import * as Colors from '@brightlayer-ui/colors';
import {
    WorkflowCard,
    WorkflowCardActions,
    WorkflowCardBody,
    WorkflowCardHeader,
    WorkflowCardInstructions,
} from '@brightlayer-ui/react-auth-workflow';
import ErrorManager from '@brightlayer-ui/react-auth-workflow/dist/components/Error/ErrorManager';
import TextField from '@mui/material/TextField';
import React, { useCallback, useEffect, useState } from 'react';
import { CreateAccountPageProps } from './types';

/**
 * Component that renders a screen for the user to enter their email address and invite code
 * to start the account creation process.
 *
 * This component has been ported over from BLUI React Workflows and modified.
 *
 * @param {CreateAccountPageProps} props - props of CreateAccountScreen base component
 *
 * @category Component
 */

export const CreateAccountPageBase: React.FC<React.PropsWithChildren<CreateAccountPageProps & { inputRef?: any }>> = (
    props
) => {
    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        emailValidator = (email: string): boolean | string => true,
        emailLabel,
        emailInitialValue: initialValue,
        emailTextFieldProps,
        inputRef,
        errorDisplayConfig,
        inviteCodeInitialValue,
        inviteCodeLabel,
        inviteCodeTextFieldProps,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        inviteCodeValidator = (code: string): boolean | string => true,
        WorkflowCardHeaderProps,
        WorkflowCardBaseProps,
        WorkflowCardInstructionProps,
        WorkflowCardActionsProps,
        ...otherProps
    } = props;

    const [emailInput, setEmailInput] = useState(initialValue ? initialValue : '');
    const [inviteCodeInput, setInviteCodeInput] = useState(inviteCodeInitialValue ? inviteCodeInitialValue : '');
    const [isEmailValid, setIsEmailValid] = useState(emailValidator(initialValue ?? '') ?? false);
    const [isInviteCodeValid, setIsInviteCodeValid] = useState(
        inviteCodeValidator(inviteCodeInitialValue ?? '') ?? false
    );
    const [emailError, setEmailError] = useState('');
    const [inviteCodeError, setInviteCodeError] = useState('');
    const [shouldValidateEmail, setShouldValidateEmail] = useState(false);
    const [shouldValidateInviteCode, setShouldValidateInviteCode] = useState(false);

    const handleEmailInputChange = useCallback(
        (email: string) => {
            setEmailInput(email);
            const emailValidatorResponse = emailValidator(email);

            setIsEmailValid(typeof emailValidatorResponse === 'boolean' ? emailValidatorResponse : false);
            setEmailError(typeof emailValidatorResponse === 'string' ? emailValidatorResponse : '');
        },
        [emailValidator]
    );

    const handleInviteCodeChange = useCallback(
        (code: string) => {
            setInviteCodeInput(code);
            const inviteCodeValidatorResponse = inviteCodeValidator(code);

            setIsInviteCodeValid(
                typeof inviteCodeValidatorResponse === 'boolean' ? inviteCodeValidatorResponse : false
            );
            setInviteCodeError(typeof inviteCodeValidatorResponse === 'string' ? inviteCodeValidatorResponse : '');
        },
        [inviteCodeValidator]
    );

    useEffect(() => {
        if (emailInput.length > 0) {
            setShouldValidateEmail(true);
            handleEmailInputChange(emailInput);
        }
        if (inviteCodeInput.length > 0) {
            setShouldValidateInviteCode(true);
            handleInviteCodeChange(inviteCodeInput);
        }
    }, []);

    return (
        <WorkflowCard {...WorkflowCardBaseProps} {...otherProps}>
            <WorkflowCardHeader {...WorkflowCardHeaderProps} />
            <WorkflowCardInstructions {...WorkflowCardInstructionProps} />
            <WorkflowCardBody>
                <ErrorManager
                    {...errorDisplayConfig}
                    mode="dialog"
                    dialogConfig={{
                        sx: {
                            '.MuiDialog-paper p': {
                                color: Colors.red[400],
                            },
                        },
                    }}
                >
                    <TextField
                        ref={inputRef}
                        type={'email'}
                        label={emailLabel}
                        fullWidth
                        value={emailInput}
                        variant="filled"
                        error={shouldValidateEmail && !isEmailValid}
                        helperText={shouldValidateEmail && emailError}
                        {...emailTextFieldProps}
                        onChange={(e): void => {
                            // eslint-disable-next-line no-unused-expressions
                            emailTextFieldProps?.onChange && emailTextFieldProps.onChange(e);
                            handleEmailInputChange(e.target.value);
                        }}
                        onKeyUp={(e): void => {
                            if (
                                e.key === 'Enter' &&
                                ((emailInput.length > 0 && isEmailValid) || WorkflowCardActionsProps?.canGoNext)
                            )
                                WorkflowCardActionsProps?.onNext?.();
                        }}
                        onBlur={(e): void => {
                            // eslint-disable-next-line no-unused-expressions
                            emailTextFieldProps?.onBlur && emailTextFieldProps.onBlur(e);
                            setShouldValidateEmail(true);
                        }}
                    />
                    <TextField
                        ref={inputRef}
                        type={'text'}
                        label={inviteCodeLabel}
                        sx={{
                            mt: { md: 4, sm: 3 },
                        }}
                        fullWidth
                        value={inviteCodeInput}
                        variant="filled"
                        error={shouldValidateInviteCode && !isInviteCodeValid}
                        helperText={shouldValidateInviteCode && inviteCodeError}
                        {...emailTextFieldProps}
                        onChange={(e): void => {
                            // eslint-disable-next-line no-unused-expressions
                            inviteCodeTextFieldProps?.onChange && inviteCodeTextFieldProps.onChange(e);
                            handleInviteCodeChange(e.target.value);
                        }}
                        onKeyUp={(e): void => {
                            if (
                                e.key === 'Enter' &&
                                ((inviteCodeInput.length > 0 && isInviteCodeValid) ||
                                    WorkflowCardActionsProps?.canGoNext)
                            )
                                WorkflowCardActionsProps?.onNext?.();
                        }}
                        onBlur={(e): void => {
                            // eslint-disable-next-line no-unused-expressions
                            inviteCodeTextFieldProps?.onBlur && inviteCodeTextFieldProps.onBlur(e);
                            setShouldValidateInviteCode(true);
                        }}
                    />
                </ErrorManager>
            </WorkflowCardBody>
            <WorkflowCardActions
                {...WorkflowCardActionsProps}
                canGoNext={
                    emailInput.length > 0 &&
                    !!isEmailValid &&
                    inviteCodeInput.length > 0 &&
                    !!isInviteCodeValid &&
                    WorkflowCardActionsProps?.canGoNext
                }
            ></WorkflowCardActions>
        </WorkflowCard>
    );
};
