import * as Colors from '@brightlayer-ui/colors';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    Stack,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Styles = {
    dialogBlock: {
        backgroundColor: Colors.blue[700],
        textAlign: 'center',
        color: Colors.white[50],
    },
    inputBox: {
        textAlign: 'center',
        margin: 2,
        fontWeight: 'bold',
    },
    inputLabel: {
        min: 10,
        textAlign: 'center',
    },
    infoOuterBox: {
        alignContent: 'center',
        textAlign: 'center',
        marginBottom: 2,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 2,
    },
};

type ActivationCodeModalProps = {
    onClickUse: (key: string) => void;
    onClickCancel: () => void;
};

export const ActivationCodeModal: React.FC<ActivationCodeModalProps> = (props) => {
    const { onClickCancel, onClickUse } = props;
    const [renderModal, setRenderModal] = useState(true);
    const [key, setKey] = useState('');
    const { t } = useTranslation();

    return (
        <>
            <Dialog open={renderModal}>
                <DialogTitle sx={Styles.dialogBlock}>{t('ACTIVATION_CODE_MODAL.ENTER_ACTIVATION_KEY')}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={Styles.inputBox}>
                        {t('ACTIVATION_CODE_MODAL.ENTER_ORGANIZATION_ACTIVATION_KEY')}
                    </DialogContentText>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="activation-key-input" sx={Styles.inputLabel}>
                            {t('ACTIVATION_CODE_MODAL.ACTIVATION_KEY')}
                        </InputLabel>
                        <Input
                            margin="dense"
                            fullWidth
                            onChange={(event): void => setKey(event.target.value)}
                            name="activation-key"
                            id="activation-key-input"
                            data-testid="activation-key-input"
                            value={key}
                            inputProps={{ min: 0, style: { textAlign: 'center' } }}
                        />
                    </FormControl>
                    <Box sx={Styles.infoOuterBox}>
                        <DialogContentText marginTop={2}>{t('ACTIVATION_CODE_MODAL.NEED_KEY')}</DialogContentText>
                    </Box>
                    <Box>
                        <Stack sx={Styles.buttonContainer} spacing={2} direction="row">
                            <Button
                                id="use-button"
                                variant="contained"
                                disabled={key.length === 0}
                                onClick={(): void => {
                                    onClickUse(key);
                                    setRenderModal(false);
                                }}
                            >
                                {t('COMMON.USE')}
                            </Button>
                            <Button
                                id="cancel-button"
                                variant="contained"
                                onClick={(): void => {
                                    onClickCancel();
                                    setRenderModal(false);
                                }}
                            >
                                {t('COMMON.CANCEL')}
                            </Button>
                        </Stack>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
