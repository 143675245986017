import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { UserRole } from './services/user/types';

export const formatDate = (date: DateTime): string => date.toFormat('MM/dd/yyyy hh:mm:ss a ZZZZ');

export const formatToReadableDate = (date: DateTime): string => date.toFormat('MMMM dd, yyyy');

export const roleText = (role: UserRole, t: TFunction): string => {
    switch (role) {
        case UserRole.OWNER:
            return t('COMMON.OWNER');
        case UserRole.DEVELOPER:
            return t('COMMON.DEVELOPER');
    }
};
