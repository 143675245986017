import * as Colors from '@brightlayer-ui/colors';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { PrivacyTip } from '@mui/icons-material';
import { Box, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CopyButton } from '../../components/CopyButton';
import { FilledTextField } from '../../components/FilledTextField';
import { GenericModal } from '../../components/GenericModal';
import { useAlert } from '../../lib/AlertContext';
import { Logger } from '../../logger';
import { OrganizationService } from '../../services/organization/organization.service';
import { OrganizationSecret, OrganizationSecretWithValue } from '../../services/organization/types';
import { formatToReadableDate } from '../../utils';

export const styles = {
    actionsContainer: { display: 'flex', justifyContent: 'space-between' },
    singleButtonActions: { display: 'flex', alignSelf: 'flex-end' },
    button: { margin: '12px' },
    contentContainer: { marginBottom: '20px' },
    circularProgress: {
        color: 'white',
    },
    simpleModalFieldset: {
        border: '0px',
    },
    confirmationQuestionText: {
        fontWeight: 600,
        fontSize: '1.05rem',
        marginBottom: '24px',
    },
    confirmationInstructionalText: {
        fontSize: '0.9rem',
    },
    secretsContainer: (theme: Theme): object => ({
        backgroundColor: Colors.yellow[100],
        paddingTop: '25px',
        paddingBottom: '40px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginTop: '30px',
        maxwidth: '100%',
    }),
    textBoxHeader: { marginBottom: '10px' },
    securityInfoBar: {
        marginBottom: '15px',
        backgroundColor: Colors.yellow[50],
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    expirationTextContainer: {
        marginTop: '-37px',
    },
    reregisterOrgErrorText: {
        color: Colors.red[500],
        paddingTop: '1rem',
    },
};

type RegenerateConfirmationModalProps = {
    secretName: string;
    organizationName: string;
    organizationId: string;
    setShowConfirmationModal: (show: boolean) => void;
    setSecret: (updatedSecret: OrganizationSecret) => void;
    isOrgRegisteredWithInstallerApp: boolean;
};

export const RegenerateConfirmationModal: React.FC<RegenerateConfirmationModalProps> = (props) => {
    const {
        secretName,
        organizationName,
        organizationId,
        setShowConfirmationModal,
        setSecret,
        isOrgRegisteredWithInstallerApp,
    } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const { showErrorToUser } = useAlert();

    const [newSecretInfo, setNewSecretInfo] = useState<OrganizationSecretWithValue>();
    const [isLoading, setIsLoading] = useState(false);
    const [reregisterOrgError, setReregisterOrgError] = useState(false);

    const reregisterOrgWithInstallerApp = async (): Promise<void> => {
        if (isOrgRegisteredWithInstallerApp) {
            try {
                await OrganizationService.shareOrgCredentialsWithExternalApplication({
                    organizationId,
                });
            } catch (err) {
                Logger.error(
                    `Something went wrong while re-registering org with installer app after secret rotation: ${JSON.stringify(
                        err
                    )}`
                );
                setReregisterOrgError(true);
            }
        }
    };

    const handleOnClick = async (): Promise<void> => {
        setIsLoading(true);
        try {
            const newSecret = await OrganizationService.rotateOrgServiceAccountSecret(organizationId, secretName);
            setNewSecretInfo(newSecret);
            setSecret(newSecret);
            await reregisterOrgWithInstallerApp();
        } catch (error) {
            Logger.error(String(error));
            showErrorToUser({
                errorTitle: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_REGENERATE_SECRET_TITLE'),
                errorDescription: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_REGENERATE_SECRET_BODY', {
                    secretName,
                }),
                confirmText: t('COMMON.TRY_AGAIN'),
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <GenericModal
                modalTestId="regenerate-confirmation-modal"
                title={t('ORGANIZATION_DETAILS_PAGE.REGENERATE_SECRET_CONFIRMATION_MODAL_TITLE')}
                cancelText={t('COMMON.CANCEL')}
                confirmText={t('ORGANIZATION_DETAILS_PAGE.REGENERATE_SECRET_CONFIRMATION_MODAL_BUTTON')}
                setShowModal={setShowConfirmationModal}
                confirmOnClick={handleOnClick}
                loading={isLoading}
            >
                <Box component="fieldset" sx={styles.simpleModalFieldset}>
                    <Typography sx={styles.confirmationQuestionText}>
                        <Trans t={t}>
                            {t('ORGANIZATION_DETAILS_PAGE.REGENERATE_SECRET_CONFIRMATION_MODAL_QUESTION', {
                                secretName,
                                organizationName,
                            })}
                        </Trans>
                    </Typography>
                </Box>
                <Box component="fieldset" sx={styles.simpleModalFieldset}>
                    <Typography sx={styles.confirmationInstructionalText}>
                        {t('ORGANIZATION_DETAILS_PAGE.REGENERATE_SECRET_CONFIRMATION_MODAL_INSTRUCTIONS')}
                    </Typography>
                </Box>
            </GenericModal>
            {newSecretInfo && (
                <GenericModal
                    modalTestId="regenerate-success-modal"
                    title={t('ORGANIZATION_DETAILS_PAGE.REGENERATE_SECRET_CONFIRMATION_MODAL_TITLE')}
                    confirmText={t('COMMON.DONE')}
                    confirmOnClick={(): void => setShowConfirmationModal(false)}
                >
                    <Box sx={styles.secretsContainer(theme)} data-testid="copy-secrets-success-modal">
                        <Typography sx={styles.textBoxHeader} variant="body1">
                            <b>{t('ORGANIZATION_DETAILS_PAGE.YOUR_SECRETS')}</b>
                        </Typography>
                        <InfoListItem
                            data-testid="credentials-warning"
                            title={t('ORGANIZATION_DETAILS_PAGE.COPY_INSTRUCTIONS')}
                            wrapSubtitle={true}
                            subtitle={t('ORGANIZATION_DETAILS_PAGE.COPY_WARNING')}
                            divider={'full'}
                            wrapTitle={true}
                            wrapInfo={true}
                            statusColor={Colors.yellow[500]}
                            subtitleSeparator={'/'}
                            icon={<PrivacyTip />}
                            sx={styles.securityInfoBar}
                        />
                        <FilledTextField
                            data-testid="new-secret-text"
                            label={`${secretName}`}
                            value={newSecretInfo.secret}
                            InputProps={{
                                readOnly: true,
                                endAdornment: <CopyButton content={newSecretInfo.secret} />,
                            }}
                        />
                        <Typography sx={styles.expirationTextContainer} variant="body2">
                            <i data-testid="regenerate-secret-expiration">
                                {t('ORGANIZATION_DETAILS_PAGE.NEW_SECRET_EXPIRATION_MESSAGE', {
                                    expiration: formatToReadableDate(DateTime.fromISO(newSecretInfo.expiration)),
                                })}
                            </i>
                        </Typography>
                    </Box>
                    {reregisterOrgError && (
                        <Typography sx={styles.reregisterOrgErrorText} variant="body1">
                            {t('ORGANIZATION_DETAILS_PAGE.REREGISTER_ORG_WITH_INSTALLER_APP_ERROR')}
                        </Typography>
                    )}
                </GenericModal>
            )}
        </>
    );
};
