import { Theme } from '@emotion/react';
import { Box, SxProps } from '@mui/material';

export const ProfileIcon = (props: { sx?: SxProps<Theme> }): JSX.Element => (
    <Box component="svg" sx={props.sx} viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="38" cy="38" r="38" fill="white" />
        <circle cx="38" cy="38" r="32" fill="#007BC1" />
        <mask id="mask0" maskUnits="userSpaceOnUse" x="12" y="12" width="52" height="52">
            <circle cx="38" cy="38" r="26" fill="white" />
        </mask>
        <g mask="url(#mask0)">
            <ellipse cx="38.5" cy="57" rx="21.5" ry="17" fill="white" />
        </g>
        <circle cx="38" cy="24" r="11" fill="white" />
    </Box>
);
