import { accountInfoSchemas } from '../../lib/schemas';

export const AccountInfoValidations = {
    firstNameValidator: (firstName: string): boolean => accountInfoSchemas.firstName.safeParse(firstName).success,
    lastNameValidator: (lastName: string): boolean => accountInfoSchemas.lastName.safeParse(lastName).success,
    companyNameValidator: (companyName?: string): boolean =>
        accountInfoSchemas.companyName.safeParse(companyName).success,
    phoneNumberValidator: (phoneNumber?: string): boolean =>
        accountInfoSchemas.phoneNumber.safeParse(phoneNumber).success,
};
