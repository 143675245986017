import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { Logger } from '../logger';
import { AppDictionaries } from './dictionary';

i18next
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(
        {
            fallbackLng: 'en',
            initImmediate: false,
            ns: ['app'],
            defaultNS: 'app',
            load: 'languageOnly',
            react: { useSuspense: false },
            interpolation: { escapeValue: false },
            resources: {
                en: {
                    app: {
                        ...AppDictionaries.english.translation,
                    },
                },
            },
        },
        // We must provide a function as second parameter, otherwise i18next errors
        (err) => {
            if (err) {
                Logger.info(err);
            }
        }
    )
    .catch((err): void => Logger.info(`There was a problem initializing i18n: ${err}`));

export default i18next;
