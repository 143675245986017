type RuntimeConfig = {
    apiDocumentationUrl: string;
    authApiDocumentationUrl: string;
};

type Window = unknown;

const runtimeConfigKeyName = 'devPortalConfig';

function hasConfig(x: unknown): x is { [runtimeConfigKeyName]: unknown } {
    if (typeof x === 'object' && x !== null) {
        return runtimeConfigKeyName in x;
    }
    return false;
}

const getReactAppEnv = (name: string): string => {
    const value = process.env[`REACT_APP_${name}`];

    if (!value) {
        throw new Error(`${name} env is undefined`);
    }

    return value;
};

export const getRuntimeConfig = (window: Window): RuntimeConfig => {
    if (process.env.NODE_ENV === 'production') {
        if (!hasConfig(window)) {
            throw new Error(`Invalid window given. (expected ${runtimeConfigKeyName} property)`);
        }

        const runtimeConfig = window[runtimeConfigKeyName];
        if (typeof runtimeConfig !== 'object' || runtimeConfig === null) {
            throw new Error(`invalid ${runtimeConfigKeyName} (expected object)`);
        }

        return runtimeConfig as RuntimeConfig;
    }

    return {
        apiDocumentationUrl: getReactAppEnv('API_DOCUMENTATION_URL'),
        authApiDocumentationUrl: getReactAppEnv('AUTH_API_DOCUMENTATION_URL'),
    };
};

export const ConfigClient = {
    apiDocumentationUrl: (window: Window): string => getRuntimeConfig(window).apiDocumentationUrl,
    authApiDocumentationUrl: (window: Window): string => getRuntimeConfig(window).authApiDocumentationUrl,
};
