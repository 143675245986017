import { useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './app/store';
import { AppContext } from './contexts/appContextProvider';
import { AlertProvider } from './lib/AlertContext';
import { AppRouter } from './router/appRouter';
import i18nAppInstance from './translations/i18n';

export const App = (): JSX.Element => {
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);

    return (
        <AppContext.Provider
            value={{
                showChangePasswordDialog,
                setShowChangePasswordDialog,
            }}
        >
            <I18nextProvider i18n={i18nAppInstance}>
                <AlertProvider>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <AppRouter />
                        </PersistGate>
                    </Provider>
                </AlertProvider>
            </I18nextProvider>
        </AppContext.Provider>
    );
};
