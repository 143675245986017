import { AppTranslationsFile } from './types';

export const english: AppTranslationsFile = {
    translation: {
        COMMON: {
            HOME: 'Home',
            LOGIN: 'Login',
            SETTINGS: 'Settings',
            ERROR: 'Error',
            COPY: 'Copy',
            SUBMIT: 'Submit',
            USE: 'Use',
            CANCEL: 'Cancel',
            TRY_AGAIN: 'Try Again',
            DONE: 'Done',
            OK: 'Ok',
            YES: 'Yes',
            NO: 'No',
            REGISTER: 'Register',
            DISPLAY_ERROR_MESSAGE:
                'Oops! We apologize, but it looks like the information you’re looking for is unavailable.',
            OWNER: 'Owner',
            DEVELOPER: 'Developer',
            EDIT: 'Edit',
            SAVE: 'Save',
            UPDATE: 'Update',
            REMOVE: 'Remove',
            ADD: 'Add',
            REVOKE: 'Revoke',
        },
        DRAWER: {
            TITLE: 'AbleEdge Developer Portal',
            API_DOCUMENTATION: 'API documentation',
            AUTH_API_LINK: 'AbleEdge Authorization API',
            API_LINK: 'AbleEdge API',
            LOGOUT: 'Logout',
            COPYRIGHT: 'Copyright © Eaton {{currentYear}}',
            COPYRIGHT_RIGHTS: 'All Rights Reserved',
            MY_ACCOUNT: 'My account',
            CHANGE_PASSWORD: 'Change password',
            HELP: 'Help',
            MY_ORGANIZATIONS: 'My organizations',
            ADVANCED_TOPICS: 'Advanced topics',
            UDP_KEYS_TITLE: 'UDP keys',
        },
        VALIDATION_ERRORS: {
            ACCOUNT_DETAILS: {
                FIRST_NAME_ERROR: 'Invalid first name',
                LAST_NAME_ERROR: 'Invalid last name',
                INVITE_CODE_ERROR: 'Invalid invite code',
                COMPANY_NAME_ERROR: 'Invalid company name',
                PHONE_NUMBER_ERROR: 'Invalid phone number',
            },
            ORGANIZATION_DETAILS: {
                EMAIL_ERROR: 'Invalid email format',
            },
        },
        HOME_PAGE: {
            TITLE: 'Introducing our AbleEdge developer portal',
            SUBTITLE:
                'Open APIs, powered by Brightlayer, allow flexibility to integrate with preferred software systems.',
            SMART_BREAKERS_TITLE: 'Eaton AbleEdge smart breakers',
            SMART_BREAKERS_SUBTITLE: 'Real-time energy monitoring',
            SMART_BREAKERS_CONTENT: `Deliver highly effective load management and metering to extend battery backup and optimize energy use at home. Our smart breakers also help avoid overloading backup system capacity during grid outages.`,
            LEARN_MORE: 'Learn more',
            GETTING_STARTED_TITLE: 'Getting Started',
            GETTING_STARTED_ACCOUNT_BUTTON: 'Login or Create Account',
            GETTING_STARTED_API_DOCS_BUTTON: 'View API Documentation',
            CARD_LOGIN_OR_CREATE_ACCOUNT_TITLE: 'Login or Create Account',
            CARD_LOGIN_OR_CREATE_ACCOUNT_BODY: 'Login or create an account to manage your access to the API.',
            CARD_GO_TO_ORGANIZATIONS_TITLE: 'Go to Organizations',
            CARD_GO_TO_ORGANIZATIONS_BODY: 'Create or manage your organizations and how you access the API.',
            CARD_VIEW_API_DOCUMENTATION_TITLE: 'View API Documentation',
            CARD_VIEW_API_DOCUMENTATION_BODY: 'View documentation to learn more about how the API works.',
        },
        ACCOUNT_DETAILS_PAGE: {
            TITLE: 'Account Details',
            INSTRUCTIONS: 'Enter your details below to complete registration',
            COMPANY_NAME_LABEL: 'Company Name',
            PHONE_NUMBER_LABEL: 'Phone Number',
            INVITE_CODE_LABEL: 'Invite Code',
            USER_ALREADY_EXISTS_ERROR: 'A user with this email is already registered.',
            BAD_REQUEST_ERROR: 'Failed to register new user.',
            MISSING_EMAIL_ERROR: 'Missing email, cannot continue with registration',
        },
        ORGANIZATION_LIST_PAGE: {
            TITLE: 'My Organizations',
            FIND_AN_ORG: 'Find an Organization',
            CREATE_NEW_ORG: 'Create new Organization',
            NO_ORGANIZATIONS_TITLE: 'No Organizations',
            NO_ORGANIZATIONS_DESCRIPTION: 'You are not a part of any organizations.',
        },
        ORGANIZATION_DETAILS_PAGE: {
            TITLE: 'My Organizations',
            POINT_OF_CONTACT: 'Point of Contact',
            ORGANIZATION_CREDENTIALS: 'Organization Credentials',
            IS_EXPIRED: 'is expired',
            CLIENT_ID: 'Client Id',
            EXPIRY: 'Expiry',
            FAILED_TO_REGISTER_ORG_TITLE: 'Failed to Register with AbleEdge Installer',
            FAILED_TO_REGISTER_ORG_BODY: `Failed to register {{organizationName}}. You can try this process again. If you continue to run into problems, please contact Eaton support.`,
            CONFIRM_ORG_REGISTRATION_TITLE: 'Confirm Registration?',
            CONFIRM_ORG_REGISTRATION_BODY: `You will be registering {{organizationName}} with the Eaton AbleEdge Installer Application. Are you sure you wish to continue?`,
            REGISTER_ORG_HEADER: 'Eaton AbleEdge Installer Application',
            REGISTER_ORG_EXPLANATION:
                'This will allow an admin to create invite codes that will allow installer app users to join the organization and commission breakers in the organization.',
            ORG_ALREADY_REGISTERED:
                '{{organizationName}} has already been registered in the Eaton AbleEdge Installer Application.',
            REGISTER_ORG: 'You can register this organization with the Eaton AbleEdge Installer Application.',
            DESCRIPTION_HEADER: 'Description',
            EDIT_BUTTON: 'Edit',
            REGENERATE_BUTTON: 'Regenerate',
            REGENERATE_SECRET_CONFIRMATION_MODAL_TITLE: 'Regenerate Organization Secret',
            REGENERATE_SECRET_CONFIRMATION_MODAL_QUESTION:
                'Are you sure you want to regenerate <strong>{{secretName}}</strong> secret from the <strong>{{organizationName}}</strong> organization?',
            REGENERATE_SECRET_CONFIRMATION_MODAL_INSTRUCTIONS:
                'You will need to save the regenerated secret value for use in all relevant API calls',
            REGENERATE_SECRET_CONFIRMATION_MODAL_BUTTON: 'Regenerate Secret',
            YOUR_SECRETS: "Your organization's secret credentials:",
            COPY_INSTRUCTIONS: 'Copy and securely save your secret credentials.',
            COPY_WARNING:
                'We will not keep these credentials for you and you will need them to access your organization.',
            NEW_SECRET_EXPIRATION_MESSAGE: 'This key expires on {{expiration}}',
            FAILED_TO_REGENERATE_SECRET_TITLE: 'Failed to Regenerate Secret',
            FAILED_TO_REGENERATE_SECRET_BODY:
                'Failed to regenerate {{secretName}}. You can try this process again. If you continue to run into problems, please contact Eaton support.',
            USERS_ASSIGNED_TO_ORG_TITLE: 'Users Assigned to Your Organization',
            ADD_USER_BUTTON: '+ Add User',
            ADD_USER_MODAL_TITLE: 'Add User to Your Organization',
            ADD_USER_MODAL_BODY: 'You will be adding {{firstName}} {{lastName}} to your organization as a {{role}}. ',
            ADD_USER_MODAL_EMAIL_LABEL: 'Email Address',
            ADD_USER_MODAL_ROLE_LABEL: 'Role',
            FAILED_TO_ADD_USER_ROLE_TO_ORG_TITLE: 'Failed to Add User Role',
            FAILED_TO_ADD_USER_ROLE_TO_ORG_BODY:
                "Failed to change this user's role to {{role}}. You can try again by clicking below.",
            FAILED_TO_REMOVE_USER_TO_ORG_TITLE: 'Failed to Remove User',
            FAILED_TO_REMOVE_USER_TO_ORG_BODY: 'Failed to remove this user. You can try again by clicking below.',
            FAILED_TO_ADD_USER_TO_ORG_TITLE: 'Failed to Add User',
            FAILED_TO_ADD_USER_TO_ORG_BODY:
                'Failed to add this user to your organization. You can try again by clicking below.',
            UPDATE_ROLE_MODAL_TITLE: 'Change User Role to {{newRole}}',
            UPDATE_ROLE_MODAL_OWNER_BODY:
                "Changing {{firstName}} {{lastName}}'s role to Owner will allow them to edit this organization, including updating others' roles.",
            UPDATE_ROLE_MODAL_DEV_BODY:
                "Changing {{firstName}} {{lastName}}'s role to Developer will limit their access, removing the ability to edit this organization and update others' roles.",
            UPDATE_ROLE_MODAL_QUESTION: 'Would you like to proceed with changing their role?',
            UPDATE_ROLE_MODAL_CONFIRMATION_TITLE: 'Role Change Success',
            UPDATE_ROLE_MODAL_CONFIRMATION_BODY:
                "{{firstName}} {{lastName}}'s role has successfully been changed to {{newRole}}.",
            MAKE_USER_OWNER_MENU_ITEM: 'Make Owner',
            MAKE_USER_DEVELOPER_MENU_ITEM: 'Make Developer',
            REMOVE_USER_MENU_ITEM: 'Remove User',
            REMOVE_USER_MODAL_BODY:
                'Are you sure that you would like to remove {{firstName}} {{lastName}} from this organization?',
            CONFIRM_ORG_REVOKE_TITLE: 'Confirm Revoke?',
            CONFIRM_ORG_REVOKE_BODY:
                'You will be revoking {{organizationName}} from being used with the AbleEdge Installer Application. Installers will no longer be able to commission breakers into this organization. Are you sure to wish to continue?',
            FAILED_TO_REVOKE_ORG_TITLE: 'Failed to Revoke from AbleEdge Installer',
            FAILED_TO_REVOKE_ORG_BODY:
                'Failed to revoke {{organizationName}}. You can try this process again. If you continue to run into problems, please contact Eaton support.',
            REREGISTER_ORG_WITH_INSTALLER_APP_ERROR:
                'The secret was regenerated successfully, but there was a problem sharing the updated credentials with the Eaton AbleEdge Installer Application. Please contact Eaton support.',
        },
        ERROR: {
            DEFAULT_ERROR_MESSAGE:
                "Oops! We apologize, but it looks like the information you're looking for is unavailable.",
        },
        CREATE_ORGANIZATION_PAGE: {
            TITLE: 'Create a New Organization',
            ORG_NAME_LABEL: 'Organization Name',
            ORG_NAME_HELPER_TEXT: 'The name of your organization',
            ORG_DESC_LABEL: 'Organization Description',
            ORG_DESC_HELPER_TEXT: 'A description of your organization',
            POC_NAME_LABEL: 'Name',
            POC_EMAIL_LABEL: 'Email',
            POC_PHONE_LABEL: 'Phone Number',
            ORG_DETAILS: 'Organization Details',
            POC_DETAILS: 'Point of Contact Details',
            SUBSCRIPTION_DETAILS: 'Subscription Details',
            ORG_DETAILS_INSTRUCTIONS:
                'Please enter the name and description of the organization you would like to create.',
            POC_INSTRUCTIONS:
                'Please enter the contact information of the person who should be contacted for all matters related to this organization.',
            ACTIVATION_KEY: 'Activation Key',
            ORG_NAME_ERROR: 'Invalid organization name',
            ORG_DESCRIPTION_ERROR: 'Invalid description for the organization',
            POC_NAME_ERROR: 'Invalid point of contact name',
            POC_EMAIL_ERROR: 'Invalid email format',
            POC_PHONE_NUMBER_ERROR: 'Invalid phone number format',
            FAILED_TO_CREATE_ORG_TITLE: 'Failed to create Organization',
            FAILED_TO_CREATE_ORG_BODY:
                'The organization creation process has failed. You can try again or cancel by clicking below.',
            CONFIRM_BEFORE_EXIT_TITLE: 'Confirm before Exiting',
            CONFIRM_BEFORE_EXIT_DESCRIPTION:
                'Please confirm that you have saved your client credentials before leaving this page. If you need to check your credentials again, press Cancel to return to this page.',
        },
        ACTIVATION_CODE_MODAL: {
            ACTIVATION_KEY: 'Activation Key',
            ENTER_ACTIVATION_KEY: 'Enter Activation Key',
            ENTER_ORGANIZATION_ACTIVATION_KEY: 'Enter the Organization Activation Key',
            NEED_KEY: 'If you need an activation key, please contact an Eaton Administrator',
        },
        CREATE_ORGANIZATION_SUCCESS_PAGE: {
            CREATED_MESSAGE: '{{organizationName}} has been created!',
            KEEP_SAFE_MESSAGE: 'Please keep the following information safe.',
            BODY: "Below are your organization's service account credentials. Please store these in a secure place. We will not keep these credentials for you and they will be lost as soon as you navigate away from this page.",
            YOUR_CLIENT_ID: "Your organization's Client ID:",
            YOUR_SECRETS: "Your application's secret credentials:",
            COPY_INSTRUCTIONS: 'Copy and securely save your secret credentials.',
            WARNING: 'We will not keep these credentials for you and you will need them to access your application.',
            EXPIRES_ON: 'This key expires on {{date}}',
        },
        EDIT_ORGANIZATION_PAGE: {
            HEADER_TITLE: 'Edit Your Organization',
            ACCESS_DENIED_TITLE: 'Access Denied',
            ACCESS_DENIED_DESCRIPTION:
                'You do not have ownership privileges for this organization and are only permitted to view the details.',
            TITLE: 'Organization Details',
            POC_DETAILS: 'Point of Contact Details',
            ORG_NAME_LABEL: 'Organization Name',
            ORG_NAME_HELPER_TEXT: 'The name of your organization',
            ORG_DESC_LABEL: 'Organization Description',
            ORG_DESC_HELPER_TEXT: 'A description of your organization',
            POC_NAME_LABEL: 'Name',
            POC_EMAIL_LABEL: 'Email',
            POC_PHONE_NUMBER_LABEL: 'Phone Number',
            ORG_NAME_ERROR: 'Invalid organization name',
            ORG_DESCRIPTION_ERROR: 'Invalid description for the organization',
            POC_NAME_ERROR: 'Invalid point of contact name',
            POC_EMAIL_ERROR: 'Invalid email format',
            POC_PHONE_NUMBER_ERROR: 'Invalid phone number format',
            FAILED_TO_EDIT_TITLE: 'Failed to Update Organization',
            FAILED_TO_EDIT_MESSAGE: 'Updating your organization has failed. Please try again.',
            NO_ORGANIZATIONS_TITLE: 'No Organizations',
            NO_ORGANIZATIONS_DESCRIPTION: 'You are not a part of this organization.',
        },
        HELP_PAGE: {
            TITLE: 'Help',
            SCROLL_TO_TOP_BUTTON: 'Scroll to Top',
            REGISTRATION_TITLE: 'Registration',
            ORGANIZATION_TITLE: 'Organization',
            CREATE_AN_ORG_TITLE: 'Create an Organization',
            CONTACT_US_TITLE: 'Contact Us',
        },
        ADVANCED_TOPICS: {
            TITLE: 'Advanced Topics',
            UDP_KEYS: {
                UDP_KEYS_TITLE: 'UDP Keys',
                KEY_TYPES_TITLE: 'Key Types',
                GENERATE_UDP_KEY_TITLE: 'Generate UDP Key',
                ASSIGN_UDP_KEY_TITLE: 'Assign UDP Key',
            },
        },
        CREATE_ACCOUNT_PAGE: {
            INVITE_CODE_LABEL: 'Invite Code',
            INVITE_CODE_ERROR: 'Invalid invite code',
            EMAIL_ALREADY_EXISTS_ERROR:
                'An account with this email already exists. Please login or use a different email.',
        },
        REGISTRATION_SUCCESS_PAGE: {
            HAS_EATON_ACCOUNT_MESSAGE:
                'Your account has been successfully linked to your Eaton account. You can log in with the same password used by your other Eaton applications.',
            SUCCESS_MESSAGE: 'Your account has been successfully created with the email {{email}}.',
        },
        ACCOUNT_INFO_PAGE: {
            FAILED_TO_UPDATE_TITLE: 'Failed to Update',
            FAILED_TO_UPDATE_MESSAGE: 'Failed to update your account information. Please try again.',
            FIRST_NAME_LABEL: 'First Name',
            LAST_NAME_LABEL: 'Last Name',
            EMAIL_LABEL: 'Email',
            PHONE_NUMBER_LABEL: 'Phone Number',
            COMPANY_NAME_LABEL: 'Company Name',
            EMAIL_CANNOT_BE_CHANGED: 'Email cannot be changed',
            TITLE: 'My Account',
        },
        CHANGE_PASSWORD: {
            TEXT: 'Set new password',
            DESCRIPTION:
                'Please select a password, keeping in mind that it will apply to all your Eaton accounts. Make sure that your password meets the requirements outlined below.',
            MISSING_USER: 'An error occurred. Please ensure that your session is active and try again.',
        },
    },
};
