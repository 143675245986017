import * as Colors from '@brightlayer-ui/colors';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { Settings } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useRef } from 'react';
import { GetUsersInOrganization } from '../../services/organization/types';

export const styles = {
    listItem: {
        maxHeight: '50px',
        '& .MuiListItemText-root': { display: 'flex', flexDirection: 'row' },
        '& .MuiTypography-root.MuiTypography-body1': { fontWeight: 'normal', fontSize: '1rem' },
        '& .MuiTypography-root.MuiTypography-subtitle2': { fontWeight: 'bold', fontSize: '1rem', marginLeft: '8px' },
    },
    rightComponent: { display: 'flex', flexDirection: 'row', alignItems: 'center', textTransform: 'lowercase' },
    settingsIcon: { color: Colors.gray[300] },
    leaveButton: { marginRight: '54px', textTransform: 'uppercase' },
    userRole: { marginRight: '54px', textTransform: 'capitalize' },
};

type UsersListItemProps = {
    user: GetUsersInOrganization;
    itemLabel: string;
    userMenuItems: Array<{ testid: string; text: string; onClick?: () => void }>;
    isUserOwner: boolean;
    showUserMenu: boolean;
    setShowUserMenu: (user: GetUsersInOrganization | null) => void;
};

export const UsersListItem: React.FC<UsersListItemProps> = (props) => {
    const { user, isUserOwner, showUserMenu, setShowUserMenu, userMenuItems, itemLabel } = props;
    const userMenuAnchor = useRef<HTMLButtonElement | null>(null);

    return (
        <InfoListItem
            data-testid={`user-list-item-${user.id}`}
            sx={styles.listItem}
            title={`${user.firstName} ${user.lastName}`}
            backgroundColor={Colors.white[50]}
            hidePadding={true}
            divider={'full'}
            rightComponent={
                <Box sx={styles.rightComponent}>
                    <Typography data-testid={`org-user-role-${user.id}`} variant="caption" sx={styles.userRole}>
                        {itemLabel}
                    </Typography>
                    {isUserOwner && (
                        <>
                            <IconButton
                                data-testid={`edit-user-icon-${user.id}`}
                                ref={userMenuAnchor}
                                onClick={(e): void => {
                                    e.stopPropagation();
                                    setShowUserMenu(user);
                                }}
                            >
                                <Settings sx={styles.settingsIcon} />
                            </IconButton>
                            <Menu
                                data-testid={'edit-user-popup'}
                                transitionDuration={{ exit: 0 }}
                                open={showUserMenu}
                                onClose={(): void => setShowUserMenu(null)}
                                anchorEl={userMenuAnchor.current}
                            >
                                {userMenuItems.map((value) => (
                                    <MenuItem
                                        data-testid={`user-menu-item-${value.testid}`}
                                        key={`user-menu-item-${value.text}`}
                                        onClick={value.onClick}
                                    >
                                        {value.text}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    )}
                </Box>
            }
        />
    );
};
